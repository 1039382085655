import React from "react";
import ReactDOM from "react-dom";

import moment from 'moment';

import { collectionPctStr, collectionNameSort, ordinalSuffix } from "../helpers";

import Ad from "./Ad";
import AddFileToCollectionModal from "./Dashboard/AddFileToCollectionModal";
import AddToCollectionModal from "./Dashboard/AddToCollectionModal";
import AddSentenceToCollectionModal from "./AddSentenceToCollectionModal";
import AddClozeFromWordsToCollectionModal from "./Dashboard/AddClozeFromWordsToCollectionModal";
import AddTextToCollectionModal from "./Dashboard/AddTextToCollectionModal";
import ChallengeFriendsPanel from "./Dashboard/ChallengeFriendsPanel";
import ClozeListeningCollectionComponent from "./Dashboard/ClozeListeningCollectionComponent";
import ClozeListeningPanel from "./Dashboard/V3ClozeListeningPanel";
import ClozeSentenceSearchModal from "./ClozeSentenceSearchModal";
import CollectionGroupingsPanel from "./Dashboard/CollectionGroupingsPanel";
import CollectionsModalTable from "./Dashboard/CollectionsModalTable";
import CollectionEditorModal from "./Dashboard/CollectionEditorModal";
import CollectionPanel from "./Dashboard/CollectionPanel";
import CollectionProgressBar from "./Dashboard/CollectionProgressBar";
import CollectionReviewSettingsModal from "./Dashboard/CollectionReviewSettingsModal";
import Core1kCollectionComponent from "./Dashboard/Core1kCollectionComponent";
import DailyGoalPanel from "./DailyGoalPanel";
import DailyReminderPanel from "./DailyReminderPanel";
import DeleteLanguagePairingModal from "./Dashboard/DeleteLanguagePairingModal";
import DownloadFavoritesModal from "./Dashboard/DownloadFavoritesModal";
import FadeIn from "./FadeIn";
import FavoritesModal from "./Dashboard/FavoritesModal";
import FastTrackComponent from "./Dashboard/FastTrackComponent";
import FastTrackPanel from "./Dashboard/FastTrackPanel";
import FastTrackV2Panel from "./Dashboard/FastTrackV2Panel";
import FlagSprite from "./FlagSprite";
import FollowClozemasterPanel from "./Dashboard/FollowClozemasterPanel";
import FrequencyCollectionsComponent from "./Dashboard/FrequencyCollectionsComponent";
import FullHistoryBtn from "./Dashboard/FullHistoryBtn";
import GoogleAdPanel from "./Dashboard/GoogleAdPanel";
import GooglePanelsAd from "./Dashboard/GooglePanelsAd";
import GoogleRightSidebarAd from "./Dashboard/GoogleRightSidebarAd";
import GrammarCollectionsComponent from "./Dashboard/GrammarCollectionsComponent";
import Icon from "./Icon";
import LanguagePairingSettingsModal from "./Dashboard/LanguagePairingSettingsModal";
import LeaderboardPanel from "./Dashboard/V3LeaderboardPanel";
import LeaderboardStatPanel from "./Dashboard/LeaderboardStatPanel";
import Loading from "./Loading";
import LoadingOverlay from "./LoadingOverlay";
import ManageCollectionModal from "./ManageCollectionModal";
import MasteredIcon from "./MasteredIcon";
import Modal from "./Modal";
import ModalFooterCloseBtn from "./ModalFooterCloseBtn";
import MoreStatsModal from "./Dashboard/MoreStatsModal";
import MyCollectionsPanel from "./Dashboard/MyCollectionsPanel";
import Onboarding from "./Onboarding";
import Panel from "./Panel";
import PlayingIcon from "./PlayingIcon";
import PlayOptionsModal from "./PlayOptionsModal";
import RandomCollectionComponent from "./Dashboard/RandomCollectionComponent";
import ResetProgressModal from "./Dashboard/ResetProgressModal";
import ReviewSettingsModal from "./Dashboard/V3ReviewSettingsModal";
import ReviewStatPanel from "./Dashboard/ReviewStatPanel";
import ReviewPanel from "./Dashboard/V3ReviewPanel";
import SharedCollectionsModal from "./Dashboard/SharedCollectionsModal";
import StreakPanel from "./Dashboard/V3StreakPanel";
import V3DailyGoalPanel from "./Dashboard/V3DailyGoalPanel";

const CollectionPanelPlayedToday = ({ collection }) => {
  if(!collection.numPlaying) {
    return null;
  }

  const c = collection; // convenience

  return (
    <>
      {!!c.numPlayedToday && <span className="tw-block md:tw-inline"><Icon name="ok" style={{ color: "#27bb4e" }} /> </span>}
      <span className="tw-block md:tw-inline">Played today: {c.numPlayedToday}</span>
      {!!c.numPlayedToday && (
        <>
          <span className="tw-hidden md:tw-inline"> / </span>
          <span className="tw-block md:tw-inline">New: {c.numNewToday}</span>
          <span className="tw-hidden md:tw-inline"> / </span>
          <span className="tw-block md:tw-inline">Review: {c.numReviewedToday}</span>
        </>
      )}
    </>
  );
};

const BtnGroup = ({ active, className = "", onClick, style = {}, values }) => {
  return (
    <div className="btn-group" data-toggle="buttons">
      {values.map((v) => (
        <button
          className={`btn btn-default ${(active === v.value ? "active" : "")} ${className}`}
          key={v.value}
          style={style}
          type="button"
          value={v.value} onClick={onClick}
        >
          {v.label}
        </button>
      ))}
    </div>
  );
};

const DarkModeToggle = ({ gameSettingsUrl }) => {
  const [theme, setTheme] = React.useState(
    $("body").hasClass("dark-mode") ? "dark" : "light"
  );

  const onChange = (theme) => {
    $.ajax({
      data: {
        settings: { theme }
      },
      method: "post",
      url: gameSettingsUrl
    })
      .fail(() => {
        // TODO!
      });

    setTheme(theme);
    $("body").toggleClass("dark-mode", theme === "dark");
  };

  return (
    <div>
      <div className="tw-mb-2">Dark Mode</div>
      <BtnGroup
        active={theme}
        className="btn-xs joystix"
        onClick={(e) => onChange(e.target.value)}
        style={{ width: 44 }}
        values={[
          { label: "On", value: "dark" },
          { label: "Off", value: "light" }
        ]}
      />
    </div>
  );
};

const DropDownMenu = ({ button, lis, right = false }) => {
  return (
    <div className="dropdown">
      {button}
      <ul className={`dropdown-menu ${right && "dropdown-menu-right"}`}>
        {lis}
      </ul>
    </div>
  );
};

const toggleDashboardCollection = ({ collection, done = () => null, fail = () => alert("Error saving! Sorry about that. Please let someone know.") }) => {
  $.ajax({
    data: { user_collection: { dashboard_collection: !collection.dashboardCollection } },
    method: "put", 
    url: collection.userCollectionUrl
  })
    .done(done)
    .fail(fail);
};

const CollectionProgressBars = ({ collection }) => {
  const c = collection;

  return (
    <>
      <div className="tw-flex tw-flex-row">
        <div className="tw-w-52 tw-mr-4">
          <PlayingIcon /> Playing: {c.numPlaying.toLocaleString()}
        </div>
        <div className="tw-flex-1">
          <div className="progress tw-mb-5">
            <div className="progress-bar progress-bar-warning" data-toggle="tooltip" style={{ width: `${c.numSentences ? c.numPlaying / c.numSentences * 100 : 0}%` }}>
              <div className="tw-ml-2.5">{collectionPctStr(c, "playing")}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="tw-flex tw-flex-row">
        <div className="tw-w-52 tw-mr-4">
          <MasteredIcon /> Mastered: {c.numMastered.toLocaleString()}
        </div>
        <div className="tw-flex-1">
          <div className="progress tw-mb-5">
            <div className="progress-bar progress-bar-success" data-toggle="tooltip" style={{ width: `${c.numSentences ? c.numMastered / c.numSentences * 100 : 0}%` }}>
              <div className="tw-ml-2.5">{collectionPctStr(c, "mastered")}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CollectionCategoryIcon = ({ isEditable = false, type }) => {
  if(type === "FastTrackV2Collection" || type === "Core1kCollection") {
    return (
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.98549 11.579L6.91836 12.516H9.97285V15.5764L10.8938 16.5H16V1.46839L15.0344 0.5H9.96838V2.49944H7.98066V3.42754L7.05821 2.50392H5.98401V1.42213L5.06453 0.5H0V15.5764L0.92096 16.5H5.98549V11.579Z" fill="#0A0A0A"/>
        <path d="M10.5067 15.0376H14.4956V1.04004H10.5067V3.03948H8.51304V5.03892H6.51936V3.03948H4.52568V1.04004H0.53833V15.0376H4.52568V9.0378H6.51936V11.0387H8.51304V9.0378H10.5067V15.0376Z" fill="#5CB85B"/>
      </svg>
    );

  }
  else if(type === null) {
    if(isEditable) {
    }
    else {
    }
  }
  else {
    return (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.98549 11.0625L6.91836 11.9946H9.97285V15.0386L10.8938 15.9573H16V1.00594L15.0344 0.0427246H9.96838V2.03148H7.98066V2.95462L7.05821 2.03594H5.98401V0.959929L5.06453 0.0427246H0V15.0386L0.92096 15.9573H5.98549V11.0625Z" fill="#0A0A0A"/>
        <path d="M10.5067 14.5029H14.4956V0.580078H10.5067V2.56884H8.51304V4.5576H6.51936V2.56884H4.52568V0.580078H0.53833V14.5029H4.52568V8.53512H6.51936V10.5254H8.51304V8.53512H10.5067V14.5029Z" fill="white"/>
      </svg>

    );
  }
  return null;
};

const CollectionsPanelCustomCollectionsControls = ({ isPro, play, sharedCollectionsUrl, showCollectionEditor }) => {
  const [sharedCollectionsModalVisible, setSharedCollectionsModalVisible] = React.useState(false);
  const sharedCollectionsModalRef = React.useRef(null);

  return (
    <>
      <div className="tw-mt-5 tw-flex tw-flex-row tw-gap-5 tw-mb-3">
        <button className="btn btn-success-outline btn-lg joystix" onClick={showCollectionEditor}>Create</button>
        <button className="btn btn-success-outline btn-lg joystix" onClick={() => setSharedCollectionsModalVisible(true)}>Shared</button>
      </div>
      {sharedCollectionsModalVisible && (
        <SharedCollectionsModal
          isPro={isPro}
          play={(collection) => sharedCollectionsModalRef.current.hide({ onHidden: () => play({ collection }) })}
          onHidden={() => setSharedCollectionsModalVisible(false)}
          ref={sharedCollectionsModalRef}
          url={sharedCollectionsUrl}
        />
      )}
    </>
  );
};

const CollectionsPanel = ({ collections, collectionType = null, customCollectionsPanel = false, description = null, first = false, isPro, manage, name = null, play, sharedCollectionsUrl, showAddToCollectionModal, showCollectionEditor, totalNumPlaying, update }) => {
  if(!collections.length && !customCollectionsPanel) {
    return null;
  }

  const [open, setOpen] = React.useState(first);
  const [togglingPin, setTogglingPin] = React.useState(false);

  React.useEffect(() => {
    // TODO! save localstorage
    console.log("open", open);
  }, [open]);

  // - description
  // - cm, machine, mine, shared, etc. icon
  // - dropdown menu
  // X pin toggle
  // X success outline if not playing
  // X first first "start here" recommendation
  // - custom collections
  // - mobile
  // X all collection types
  // X collection groupings
  // - panel ordering
  // X pro locked

  const togglePin = (collection) => {
    setTogglingPin(true);
    toggleDashboardCollection({
      collection,
      done: (data) => {
        setTogglingPin(false);
        update(data.collection);
      }
    });
  };

  return (
    <Panel className={open ? "open" : ""} attrs={{ "data-type": collectionType }}>
      <div className="tw-flex tw-flex-row tw-items-start tw-justify-start tw-gap-5">
        <button className="btn btn-default toggle-open" onClick={() => setOpen(!open)}><Icon name={`chevron-${open ? "up" : "down"}`} /></button>
        <div className="md:tw-flex md:tw-flex-row md:tw-gap-5 md:tw-items-center">
          <div className="tw-text-5xl tw-font-bold">{name || collections[0].name}</div>
          <div>{collections.length > 1 && <>{collections.length} Collections / </>}{collections.reduce((n, c) => n + c.numSentences, 0).toLocaleString()} Sentences</div>
        </div>
      </div>
      {open && (
        <div className="tw-mt-5">
          {description && (
            <p>{description}</p>
          )}
          {customCollectionsPanel && (
            <CollectionsPanelCustomCollectionsControls
              isPro={isPro}
              play={play}
              sharedCollectionsUrl={sharedCollectionsUrl}
              showCollectionEditor={showCollectionEditor}
            />
          )}
          {collections.map((c, index) => (
            <div className={`${collections.length > 1 ? "tw-py-10" : ""} tw-border-0 ${index === 0 ? "" : "tw-border-t tw-border-solid tw-border-divider"}`}>
              <div className="tw-flex tw-flex-row tw-mb-5">
                <div className="tw-flex-1 tw-flex tw-flex-col tw-flex-wrap md:tw-flex-row md:tw-items-center">
                  {(collections.length > 1 || customCollectionsPanel) && <div className="tw-text-4xl tw-font-bold tw-mr-5">{c.name}</div>}
                  {/*<CollectionCategoryIcon isEditable={c.isEditable} type={c.type} />*/}
                  <div className="tw-flex tw-flex-row-reverse md:tw-flex-row tw-justify-end md:tw-justify-start tw-items-center tw-gap-5 tw-mr-5 tw-flex-wrap tw-mt-2 md:tw-mt-0">
                    {collections.length > 1 && <div className="">{c.numSentences.toLocaleString()} Sentences</div>}
                    {!!c.description && (
                      <div><button className="btn btn-default btn-xs" data-content={c.description} data-html="true" data-toggle="popover" data-trigger="focus"><Icon name="info-sign" /></button></div>
                    )}
                  </div>
                </div>
                <div className="tw-flex tw-flex-row tw-gap-5 tw-items-start">
                  <button
                    className={`tw-hidden md:tw-inline-block btn btn-default btn-sm ${c.dashboardCollection ? "active" : ""}`}
                    disabled={togglingPin}
                    onClick={() => togglePin(c)}
                  >
                    <Icon name="pushpin" /> {c.dashboardCollection ? "Unpin from" : "Pin to"} Dashboard
                  </button>
                  <DropDownMenu
                    button={<button className="btn btn-default btn-sm" data-toggle="dropdown"><Icon name="option-horizontal" /></button>}
                    lis={
                      <>
                        <li className="md:tw-hidden"><a data-target="#" role="button" onClick={() => togglePin(c)}><Icon name="pushpin" /> {c.dashboardCollection ? "Unpin from" : "Pin to"} Dashboard</a></li>
                        {c.isEditable && <li><a data-target="#" role="button" onClick={() => showAddToCollectionModal(c)}><Icon name="plus" /><span className="tw-ml-5">Add Sentences</span></a></li>}
                        <li><a data-target="#" role="button" onClick={() => manage(c)}><Icon name="list" /><span className="tw-ml-5">Manage Sentences</span></a></li>
                        {c.isEditable && <li><a data-target="#" role="button" onClick={() => showCollectionEditor(c)}><Icon name="pencil" /><span className="tw-ml-5">Edit Collection</span></a></li>}
                      </>
                    }
                    right={true}
                  />
                </div>
              </div>
              <CollectionProgressBars
                collection={c}
              />
              <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-5 tw-mb-5">
                <button
                  className={`btn btn-success${true || c.dashboardCollection || (first && totalNumPlaying === 0 && index === 0) ? "" : "-outline"} btn-block joystix`}
                  disabled={(c.proOnly || !c.type) && !isPro}
                  onClick={() => play({ collection: c })}
                >
                  Play <Icon name="chevron-right" />
                </button>
                {!!c.numPlaying && (
                  <div className="tw-flex sm:tw-min-w-96">
                    <button className="btn btn-success-outline btn-sm joystix tw-flex-1" disabled={!c.numReadyForReview} onClick={() => play({ collection: c, scope: "ready_for_review" })}>Review ({c.numReadyForReview})</button>
                  </div>
                )}
              </div>
              {(c.proOnly || !c.type) && !isPro && <div className="tw-text-center tw-mb-5"><a href="/pro" className="btn btn-default btn-sm joystix"><Icon name="lock" /> Pro!</a></div>}
              {first && totalNumPlaying === 0 && index === 0 && (
                <div className="tw-text-center joystix tw-mb-5">👉👉👉 Not sure where to start? Start here! 👈👈👈</div>
              )}
              <div className="tw-flex tw-flex-row tw-justify-end">
                <div className="tw-flex-1">
                  {!!c.user && (
                    <div><Icon name={c.public ? "users" : "lock"} type="fa" /> {c.public ? "Shared" : "Private"}</div>
                  )}
                  {!!c.user && !c.isEditable && (
                    <div>Created by <a href={`/players/${c.user.username}`} target="_blank">{c.user.username}</a></div>
                  )}
                  <CollectionPanelPlayedToday
                    collection={c}
                  />
                </div>
                {!!c.numMarkedAsKnown && <div>Marked as Known: {c.numMarkedAsKnown}</div>}
              </div>
            </div>
          ))}
        </div>
      )}
    </Panel>
  );
};

function getCurrentDateStr() {
  const date = new Date();

  // Format day/month/year to two digits
  const formattedDate = ('0' + date.getDate()).slice(-2);
  const formattedMonth = ('0' + (date.getMonth() + 1)).slice(-2);
  const formattedYear = date.getFullYear().toString().substr(2,2);

  return [formattedYear, formattedMonth, formattedDate].join('-');
}

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "dashboard",
      activeMobilePanels: 0,
      aboutToPlayCollection: null,
      aboutToPlayScope: null,
      addingToCollection: null,
      addSentenceToCollectionVisible: false,
      collectionEditorCollection: null,
      collectionEditorModalVisible: false,
      favoritesModalVisible: false,
      loading: true,
      managingCollection: null,
      managingCollectionScope: null,
      moreStatsVisible: false,
      reviewModalVisible: false,
      reviewSettingsModalVisible: false
    };
  }

  getCollectionsStats(collections) {
    let numPlaying = 0;
    let numMastered = 0;
    let numReadyForReview = 0;
    let numFavorited = 0;

    for(let i = 0, n = collections.length; i < n; i++) {
      numPlaying += collections[i].numPlaying;
      numMastered += collections[i].numMastered;
      // if(collections[i].dashboardCollection) {
        numReadyForReview += collections[i].numReadyForReview;
      // }
      numFavorited += collections[i].numFavorited;
    }

    return {
      numFavorited,
      numMastered,
      numPlaying,
      numReadyForReview
    };
  }

  componentDidMount() {
    const $dashboard = $('[data-react-class="DashboardV3"]');

    $dashboard.popover({
      selector: '[data-toggle="popover"]'
    });
    $dashboard.on('show.bs.popover', '[data-toggle="popover"]', function(e) {
      $('[data-toggle="popover"]').not(this).popover('destroy');
    });
    $dashboard.on('inserted.bs.popover', '[data-toggle="popover"]', function(e) {
      $('.popover .popover-title').append(
        '<button type="button" class="close" data-dismiss="popover" aria-label="Close" style="margin-top: -4px"><span aria-hidden="true">&times;</span></button>'
      );
    });
    $dashboard.on('click', '.popover .close', () => {
      $('[data-toggle="popover"]').popover('destroy');
    });

    $('body').tooltip({ selector: '[data-toggle="tooltip"]' });

    // run check to load voices
    window.clozemaster.systemTtsAvailable(this.props.targetLanguageIso, this.props.targetLanguageCode);

    this.loadLanguagePairing();
  }

  loadLanguagePairing() {
    this.setState({ loading: true });
    const {
      includeFastTrackV2Collections,
      languagePairingUrl
    } = this.props;

    $.ajax({
      data: { include_fast_track_v2_collections: includeFastTrackV2Collections },
      url: languagePairingUrl
    }).done((data) => {
      const { collectionGroupings, collections, languagePairing, user } = data;
      console.log(data);

      const {
        clozeFromWordsUrl,
        clozeListening,
        clozeSentencesUrl,
        collectionFileImportUrl,
        collectionsUrl,
        currentLevelPoints,
        currentStreakDays,
        currentStreakIncludesToday,
        currentWeekLeaderboardRank,
        dailyGoalHoursLeftToday,
        dailyGoalPointsPerDay,
        dailyGoalStreak,
        dailyGoalUrl,
        dailyReminderEmail,
        dailyReminderEmailUrl,
        dailyStatsWebUrl,
        daysPlayedCount,
        gameSettingsUrl,
        leaderboardsUrl,
        level,
        listeningTrialExpired,
        maxReviewsPerRound,
        moreStatsUrl,
        nextLevelPoints,
        nextReviewByLevel,
        numPointsToday,
        past7DaysNewReview,
        playClozeListeningWebUrl,
        playOptions,
        playWebUrl,
        prevWeekLeaderboardRank,
        progressPerChunk,
        quickClozeUrl,
        rankingsUrl,
        resetProgressUrl,
        reviewSettingsUrl,
        score,
        sharedCollectionsUrl,
        showDaysPlayedInsteadOfStreak,
        speakingTrialExpired,
        useProgressChunks
      } = languagePairing;

      const { isSignedIn } = this.props;
      // this should only ever happen once after signing up
      if(isSignedIn && !user.hasRunPlayTutorial && !!window.localStorage.getItem("hasRunPlayTutorial")) {
        this.saveHasRunPlayTutorial();
      }

      this.setState(Object.assign(this.getCollectionsStats(collections), {
        clozeFromWordsUrl,
        clozeListening,
        clozeSentencesUrl,
        collectionFileImportUrl,
        collectionGroupings,
        collections,
        collectionsUrl,
        currentLevelPoints,
        currentStreakDays,
        currentStreakIncludesToday,
        currentWeekLeaderboardRank,
        dailyGoalHoursLeftToday,
        dailyGoalPointsPerDay,
        dailyGoalStreak,
        dailyGoalUrl,
        dailyReminderEmail,
        dailyReminderEmailUrl,
        dailyStatsUrl: dailyStatsWebUrl,
        daysPlayedCount,
        gameSettingsUrl,
        leaderboardsUrl,
        level,
        listeningTrialExpired,
        loading: false,
        maxReviewsPerRound,
        moreStatsUrl,
        nextLevelPoints,
        nextReviewByLevel,
        numPointsToday,
        past7DaysNewReview,
        playClozeListeningUrl: playClozeListeningWebUrl,
        playLanguagePairingWebUrl: playWebUrl,
        playOptions,
        prevWeekLeaderboardRank,
        progressPerChunk,
        quickClozeUrl,
        rankingsUrl,
        resetProgressUrl,
        reviewSettingsUrl,
        score,
        sharedCollectionsUrl,
        showDaysPlayedInsteadOfStreak,
        speakingTrialExpired,
        useProgressChunks,
        user
      }), () => this.initPast7DaysChart());
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.activeTab === "dashboard" && prevState.activeTab !== "dashboard") {
      this.initPast7DaysChart();
    }

    if(this.state.activeTab !== prevState.activeTab) {
      window.scrollTo(0, 0);
    }
  }

  initPast7DaysChart() {
    const { past7DaysNewReview } = this.state;
    const dates = [];
    const newCounts = [];
    const reviewCounts = [];

    past7DaysNewReview.forEach((d) => {
      dates.push(moment(d.date, "YYYY-MM-DD").format('ddd MMM D'));
      newCounts.push(d.numNew);
      reviewCounts.push(d.numReview);
    });

    const ctx = this.past7DaysCanvas.getContext('2d');
    const data = {
      labels: dates,
      datasets: [
        {
          label: "New",
          fillColor: "rgba(20,220,20,0.2)",
          strokeColor: "rgba(20,220,20,1)",
          pointColor: "rgba(20,220,20,1)",
          pointStrokeColor: "#fff",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "rgba(20,220,20,1)",
          data: newCounts
        },
        {
          label: "Reviewed",
          fillColor: "rgba(151,187,205,0.5)",
          strokeColor: "rgba(151,187,205,1)",
          pointColor: "rgba(151,187,205,1)",
          pointStrokeColor: "#fff",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "rgba(151,187,205,1)",
          data: reviewCounts
        }
      ]
    };
    const chart = new Chart(ctx).Line(data, { multiTooltipTemplate: "<%= datasetLabel %>: <%= value %>", });
  }

  getPast7DaysNewReviewAvg(attr) {
    const avg = this.state.past7DaysNewReview.reduce((n, d) => n += d[attr], 0) / 7.0;
    return Math.round(avg * 10) / 10;
  }

  saveHasRunPlayTutorial() {
    $.ajax({
      contentType: "application/json",
      data: JSON.stringify({
        user: { has_run_play_tutorial: true }
      }),
      method: "put",
      url: "/api/v1/users"
    });
  }

  renderStats() {
    const state = this.state;
    const { isSignedIn } = this.props;

    return (
      <div className="stats-panels-container">
        <div className="stats-panels-table">
          <div className="stats-panels">
            <div className="stat-panel score">
              <div className="title">Score</div>
              <div className="value">{state.score.toLocaleString()}</div>
              <div>
                <small>
                  <PlayingIcon style={{ marginRight: 3 }} />
                  Playing: <strong>{state.numPlaying.toLocaleString()}</strong>
                </small>
              </div>
              <div>
                <small>
                  <MasteredIcon style={{ marginRight: 3 }} />
                  Mastered: <strong>{state.numMastered.toLocaleString()}</strong>
                </small>
              </div>
            </div>
            <div className="stat-panel">
              <div className="title">
                Level <span className="glyphicon glyphicon-question-sign" data-toggle="tooltip" title="Score more points to level up"></span>
              </div>
              <div className="value">{state.level}</div>
              <div className="level-progress">
                <div className="progress" style={{ height: '10px', marginTop: '0px', marginBottom: '4px' }}>
                  <div className="progress-bar progress-bar-success progress-bar-striped" style={{ width: (((state.score - state.currentLevelPoints) / (state.nextLevelPoints - state.currentLevelPoints)) * 100) + '%' }}></div>
                </div>
              </div>
              <small style={{ display: 'block', lineHeight: 1.1 }}>
                {(state.nextLevelPoints - state.score).toLocaleString()} more points to level {state.level + 1}
              </small>
            </div>
            <div className={'stat-panel streak' + (state.currentStreakDays === 0 ? ' zero' : '') }>
              <div className="title">
                Streak <small style={{ fontWeight: "normal" }}><a href="/settings#timezone">{this.props.timeZone}</a></small>
              </div>
              <div className="value">
                {state.currentStreakDays} Day{state.currentStreakDays === 1 ? '' : 's'}
                {state.currentStreakIncludesToday ? <span className="glyphicon glyphicon-ok played-today" title="Streak includes today" data-toggle="tooltip"></span> : ''}
              </div>
              <div className="averages">
                <div className="title">
                  7-day Av<span className="hidden-sm hidden-md">era</span>g<span className="hidden-sm hidden-md">e</span>
                  <span className="glyphicon glyphicon-question-sign" style={{ marginLeft: 4 }} data-toggle="tooltip" title="Average number of sentences played per day for the last 7 days"></span>
                </div>
                <span className="average average-new" data-toggle="tooltip" title="New sentences">
                  <small className="glyphicon glyphicon-plus"></small>
                  <span className="count" style={{ marginLeft: 4 }}>{this.getPast7DaysNewReviewAvg('numNew')}</span>
                </span>
                <span className="average average-review" data-toggle="tooltip" title="Review sentences">
                  <small className="glyphicon glyphicon-refresh"></small>
                  <span className="count" style={{ marginLeft: 4 }}>{this.getPast7DaysNewReviewAvg('numReview')}</span>
                </span>
              </div>
            </div>
            <ReviewStatPanel
              collections={state.collections}
              numReadyForReview={state.numReadyForReview}
              onPlay={(collection) => this.setState({ aboutToPlayCollection: collection, aboutToPlayScope: 'ready_for_review' })}
              onReviewSettingsBtnClick={this.onReviewSettingsBtnClick.bind(this)}
              playLanguagePairingUrl={state.playLanguagePairingWebUrl}
            />
            <LeaderboardStatPanel
              baseLanguageFlagIso={this.props.baseLanguageFlagIso}
              currentWeekLeaderboardRank={state.currentWeekLeaderboardRank}
              isSignedIn={isSignedIn}
              leaderboardsUrl={state.leaderboardsUrl}
              prevWeekLeaderboardRank={state.prevDayRanking}
              ranking={state.ranking}
              rankingsUrl={state.rankingsUrl}
              targetLanguageFlagIso={this.props.targetLanguageFlagIso}
              useRankingsForLeaderboard={this.props.useRankingsForLeaderboard}
            />
            {/*
            <div className="stat-panel review">
              <div className="title">Ready for Review</div>
              <div className="value">{state.numReadyForReview.toLocaleString()}</div>
              <button className="btn btn-primary btn-block joystix review default" disabled={!state.numReadyForReview}>
                Review
                <Icon name="chevron-right" />
              </button>
              <small>
                <a href="javascript:void(0);" data-toggle="popover" data-trigger="focus" data-html="true" title="Reviews" data-placement="auto right" data-content={ "Clozemaster uses a <a href=\"https://en.wikipedia.org/wiki/Spaced_repetition\" target=\"_blank\">spaced repetition system</a> to help you remember what you learn. Intervals are set to 1 day (25% Mastered), 10 days (50% Mastered), 30 days (75% Mastered), and 180 days (100% Mastered) by default. <a href=\"/pro\">Clozemaster Pro</a> users can customize the intervals. You can also manually master or reset sentences while playing." }>
                  How reviews work
                </a>
              </small>
            </div>
            <div className="stat-panel leaderboard">
              <div className="title">Leaderboard</div>
              <div className="value">
                {state.ranking ? '' : 'N/A'}
                {state.ranking && state.prevDayRanking ?
                  <span className={'glyphicon trend glyphicon-arrow-' + (state.ranking > state.prevDayRanking ? 'up uptrend' : 'down downtrend')} data-toggle="tooltip" title={'Ranked ' + state.prevDayRanking + ' yesterday UTC'}></span>
                  : null
                }
              </div>
              <small>This week</small>
              <button className="btn btn-xs btn-default btn-block" data-toggle="modal" data-target=".challenge-leaderboard.modal">View</button>
            </div>
            */}
            <div className="stat-panel favorites">
              <div className="title">Favorites</div>
              <div className="value">{state.numFavorited.toLocaleString()}</div>
              <button onClick={() => this.openFavoritesModal('play')} className="btn btn-success btn-xs joystix btn-block play" disabled={!state.numFavorited} style={{ fontSize: '11px', padding: 0 }}>
                Play <span className="glyphicon glyphicon-chevron-right"></span>
              </button>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '50%', paddingRight: '3px' }}>
                      <button onClick={() => this.openFavoritesModal('view')} disabled={!state.numFavorited} className="btn btn-default btn-xs btn-block view" style={{ padding: 0, marginTop: '4px' }}>View</button>
                    </td>
                    <td style={{ width: '50%', paddingLeft: '3px' }}>
                      <span data-toggle="tooltip" data-placement="bottom" title="Download">
                        <button onClick={() => this.openFavoritesModal('download')} disabled={!state.numFavorited} className="btn btn-primary btn-xs btn-block download" style={{ padding: 0, marginTop: '4px' }}>
                          <span className="glyphicon glyphicon-cloud-download download"></span>
                        </button>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }

  openFavoritesModal(action) {
    this.setState({ favoritesModalVisible: action });
  }

  renderReviewModal() {
    const { reviewModalVisible, collections } = this.state;
    // TODO! aboutToPlayScope = 'ready_for_review'
  }

  renderDownloadFavoritesModal() {
    const { downloadFavoritesCollection, user } = this.state;

    if(!downloadFavoritesCollection) {
      return null;
    }

    return (
      <DownloadFavoritesModal
        collection={downloadFavoritesCollection}
        isPro={user.isPro}
        onHidden={() => this.setState({ downloadFavoritesCollection: null })}
      />
    );
  }

  renderFavoritesModal() {
    const { collections, favoritesModalVisible, playLanguagePairingWebUrl, user } = this.state;

    if(!favoritesModalVisible) {
      return null;
    }

    return (
      <FavoritesModal
        action={favoritesModalVisible}
        collections={collections}
        isPro={user.isPro}
        onDownload={(collection) => this.favoritesModal.hide({
          onHidden: () => this.setState({ downloadFavoritesCollection: collection })
        })}
        onHidden={() => this.setState({ favoritesModalVisible: false })}
        onPlay={(collection) => this.favoritesModal.hide({
          onHidden: () => this.setState({ aboutToPlayCollection: collection, aboutToPlayScope: 'favorited' })
        })}
        onView={(collection) => this.favoritesModal.hide({
          onHidden: () => this.setState({ managingCollection: collection, managingCollectionScope: 'favorited' })
        })}
        playLanguagePairingUrl={playLanguagePairingWebUrl}
        ref={(e) => this.favoritesModal = e}
      />
    );
  }

  renderAnonPlayPanel() {
    if(this.props.isSignedIn) {
      return null;
    }

    if(this.props.simpleAnonPlayPanel) {
      return (
        <div className="alert alert-success anon-play text-center" style={{ marginBottom: 10 }}>
          <h2>Get in the game, track your progress, and more!</h2>
          <a href="/sign-up" className="btn btn-success joystix btn-lg" style={{ minWidth: 280 }}>Sign up</a>
        </div>
      );
    }

    return (
      <p className="alert alert-success anon-play">
        <h2>
          <a href="/sign-up" className="btn btn-success joystix">Sign up</a> or <a href="/login" className="btn btn-success joystix">Login</a> to get in the game, track your progress, and more! Or try out Clozemaster by playing random sentences in anonymous mode.
          <button className="btn btn-success btn-lg btn-block joystix" onClick={() => this.setState({ aboutToPlayCollection: { name: "Random", playWebUrl: this.state.playLanguagePairingWebUrl } })} style={{ marginTop: 10 }}>Play <Icon name="chevron-right" /></button>
        </h2>
      </p>
    );
  }

  renderCore1kCollection() {
    const { collections, progressPerChunk, useProgressChunks } = this.state;
    const { isSignedIn } = this.props;
    const core1kCollection = collections.find((c) => c.type === "Core1kCollection");

    if(!core1kCollection) {
      return null;
    }

    return (
      <CollectionPanel
        className="core1k-collection"
        collection={core1kCollection}
        fallbackDescription="Play a collection of the most useful sentences and vocabulary you need to know."
        isSignedIn={isSignedIn}
        manage={() => this.setState({ managingCollection: core1kCollection })}
        play={() => this.setState({ aboutToPlayCollection: core1kCollection })}
        progressPerChunk={progressPerChunk}
        useProgressChunks={useProgressChunks}
      />
    );
  }

  renderFastTrackPanel({ legacy = false } = {}) {
    const { collections, progressPerChunk, useProgressChunks } = this.state;
    const { isSignedIn } = this.props;
    const fastTrackCollection = collections.find((c) => c.type === 'FastTrackCollection');

    if(!fastTrackCollection) {
      return null;
    }

    return (
      <FastTrackPanel
        className="fast-track"
        collection={fastTrackCollection}
        fallbackDescription={`Play ${fastTrackCollection.numSentences.toLocaleString()} sentences with ${fastTrackCollection.numSentences.toLocaleString()} unique cloze-words in order of difficulty.`}
        helpText="Fast and efficient language learning. Each word in each sentence is matched against a frequency list (a list of words ordered by how often they're likely to occur). The most difficult (least common) word is then selected as the missing word. The Fast Track has one sentence for each missing word, and the sentences are played in order of difficulty, so you're always making progress."
        isSignedIn={isSignedIn}
        manage={() => this.setState({ managingCollection: fastTrackCollection })}
        name={legacy ? "Legacy Fast Track" : fastTrackCollection.name}
        panelId={legacy ? "legacy-fast-track" : null}
        play={() => this.setState({ aboutToPlayCollection: fastTrackCollection })}
        progressPerChunk={progressPerChunk}
        useProgressChunks={useProgressChunks}
      />
    );
  }

  renderLegacyFastTrack() {
    const { collections } = this.state;
    const fastTrackV2Collections = collections.filter((c) => c.type === "FastTrackV2Collection");
    if(fastTrackV2Collections.length) {
      return this.renderFastTrackPanel({ legacy: true });
    }
  }

  renderMainPanel() {
    const { collections, progressPerChunk, useProgressChunks } = this.state;
    const { isSignedIn } = this.props;
    const core1kCollection = collections.find((c) => c.type === "Core1kCollection");
    const fastTrackV2Collections = collections.filter((c) => c.type === "FastTrackV2Collection");
    const fastTrackCollection = collections.find((c) => c.type === 'FastTrackCollection');
    const randomCollection = collections.find((c) => c.type === 'RandomCollection');

    if(fastTrackV2Collections.length) {
      // render these
      // then core1k if present
      return (
        <>
          <FastTrackV2Panel
            collections={fastTrackV2Collections}
            hasFastTrackV1Progress={!!fastTrackCollection && fastTrackCollection.numPlaying > 0}
            isSignedIn={isSignedIn}
            manage={(collection) => this.setState({ managingCollection: collection })}
            play={(collection, scope) => this.setState({ aboutToPlayCollection: collection, aboutToPlayScope: scope })}
            progressPerChunk={progressPerChunk}
            useProgressChunks={useProgressChunks}
          />
          {this.renderCore1kCollection()}
        </>
      );
    }

    if(core1kCollection || fastTrackCollection) {
      // <FastTrackComponent
      //   fastTrackCollection={fastTrackCollection}
      //   isSignedIn={isSignedIn}
      //   manage={(collection) => this.setState({ managingCollection: collection })}
      //   play={(collection) => this.setState({ aboutToPlayCollection: collection })}
      //   progressPerChunk={progressPerChunk}
      //   useProgressChunks={useProgressChunks}
      // />
      return (
        <>
          {this.renderFastTrackPanel()}
          {this.renderCore1kCollection()}
        </>
      );
    }

    if(!randomCollection) {
      return null;
    }

    return (
      <CollectionPanel
        className="random-collection"
        collection={randomCollection}
        fallbackDescription={`Play ${randomCollection.numSentences.toLocaleString()} sentences in random order.`}
        isSignedIn={isSignedIn}
        manage={() => this.setState({ managingCollection: randomCollection })}
        play={() => this.setState({ aboutToPlayCollection: randomCollection })}
        progressPerChunk={progressPerChunk}
        useProgressChunks={useProgressChunks}
      />
    );

    // return (
    //   <RandomCollectionComponent
    //     collection={randomCollection}
    //     isSignedIn={isSignedIn}
    //     manage={() => this.setState({ managingCollection: randomCollection })}
    //     play={() => this.setState({ aboutToPlayCollection: randomCollection })}
    //     progressPerChunk={progressPerChunk}
    //     useProgressChunks={useProgressChunks}
    //   />
    // );
  }

  renderFrequencyCollectionsPanel() {
    const { collections, playLanguagePairingWebUrl, progressPerChunk, useProgressChunks, user } = this.state;
    const frequencyCollections = collections.filter((c) => c.type === 'FrequencyCollection');
    if(!frequencyCollections.length) {
      return null;
    }
    return (
      <FrequencyCollectionsComponent
        collections={frequencyCollections}
        isSignedIn={this.props.isSignedIn}
        manage={(collection) => this.setState({ managingCollection: collection })}
        play={(collection, scope) => this.setState({ aboutToPlayCollection: collection, aboutToPlayScope: scope })}
        playLanguagePairingWebUrl={playLanguagePairingWebUrl}
        progressPerChunk={progressPerChunk}
        useProgressChunks={useProgressChunks}
      />
    );
  }

  renderGrammarCollectionsPanel() {
    const { collections, progressPerChunk, useProgressChunks, user } = this.state;
    const grammarCollections = collections.filter((c) => c.type === 'GrammarCollection');
    if(!grammarCollections.length) {
      return null;
    }
    return (
      <GrammarCollectionsComponent
        collections={grammarCollections}
        isPro={user.isPro}
        isSignedIn={this.props.isSignedIn}
        manage={(collection) => this.setState({ managingCollection: collection })}
        play={(collection) => this.setState({ aboutToPlayCollection: collection })}
        progressPerChunk={progressPerChunk}
        useProgressChunks={useProgressChunks}
      />
    );
  }

  showAddToCollectionModal(collection) {
    this.setState({
      addingToCollection: collection,
      addToCollectionVisible: true
    });
  }

  showCollectionEditor(collection) {
    this.setState({
      collectionEditorCollection: collection,
      collectionEditorModalVisible: true
    });
  }

  renderMyCollectionsPanel() {
    const { collections, sharedCollectionsUrl, progressPerChunk, useProgressChunks } = this.state;
    const myCollections = collections.filter((c) => !c.type);
    return (
      <MyCollectionsPanel
        add={(collection) => this.setState({ addingToCollection: collection, addToCollectionVisible: true })}
        collections={myCollections}
        collectionsUrl={this.state.collectionsUrl}
        isPro={this.state.user.isPro}
        isSignedIn={this.props.isSignedIn}
        manage={(collection) => this.setState({ managingCollection: collection })}
        onCollectionCreate={(collection) => {
          const collections = JSON.parse(JSON.stringify(this.state.collections));
          collections.unshift(collection);
          this.setState({ collections });
        }}
        onCollectionDelete={(collection) => {
          const collections = JSON.parse(JSON.stringify(this.state.collections));
          for(let i = 0, n = collections.length; i < n; i++) {
            if(collections[i].id === collection.id) {
              collections.splice(i, 1);
              break;
            }
          }
          this.setState({ collections });
        }}
        onCollectionUpdate={(collection) => {
          const collections = JSON.parse(JSON.stringify(this.state.collections));
          for(let i = 0, n = collections.length; i < n; i++) {
            if(collections[i].id === collection.id) {
              collections[i] = collection;
              break;
            }
          }
          this.setState({ collections });
        }}
        play={(collection) => this.setState({ aboutToPlayCollection: collection })}
        progressPerChunk={progressPerChunk}
        sharedCollectionsUrl={sharedCollectionsUrl}
        teachingUrl={this.props.teachingUrl}
        useProgressChunks={useProgressChunks}
      />
    );
  }

  renderCollectionGroupingsPanel() {
    const { collectionGroupings, collections, progressPerChunk, useProgressChunks, user } = this.state;
    if(!collectionGroupings || !collectionGroupings.length) {
      return null;
    }

    return (
      <CollectionGroupingsPanel
        collectionGroupings={collectionGroupings}
        collections={collections}
        isPro={user.isPro}
        isSignedIn={this.props.isSignedIn}
        manage={(collection) => this.setState({ managingCollection: collection })}
        play={(collection) => this.setState({ aboutToPlayCollection: collection })}
        progressPerChunk={progressPerChunk}
        useProgressChunks={useProgressChunks}
      />
    );
  }

  renderRandomCollection() {
    const { collections, progressPerChunk, useProgressChunks } = this.state;
    const { isSignedIn } = this.props;
    const core1kCollection = collections.find((c) => c.type === "Core1kCollection");
    const fastTrackCollection = collections.find((c) => c.type === "FastTrackCollection");
    const fastTrackV2Collection = collections.find((c) => c.type === "FastTrackV2Collection");
    const randomCollection = collections.find((c) => c.type === "RandomCollection");
    if((!!core1kCollection || !!fastTrackCollection || !!fastTrackV2Collection) && !!randomCollection) {
      return (
        <CollectionPanel
          className="random-collection"
          collection={randomCollection}
          fallbackDescription={`Play ${randomCollection.numSentences.toLocaleString()} sentences in random order.`}
          isSignedIn={isSignedIn}
          manage={() => this.setState({ managingCollection: randomCollection })}
          play={() => this.setState({ aboutToPlayCollection: randomCollection })}
          progressPerChunk={progressPerChunk}
          useProgressChunks={useProgressChunks}
        />
      );
      // return (
      //   <RandomCollectionComponent
      //     collection={randomCollection}
      //     isSignedIn={isSignedIn}
      //     manage={() => this.setState({ managingCollection: randomCollection })}
      //     play={() => this.setState({ aboutToPlayCollection: randomCollection })}
      //     progressPerChunk={progressPerChunk}
      //     useProgressChunks={useProgressChunks}
      //   />
      // );
    }
  }

  renderNsfwCollection() {
    const { collections, progressPerChunk, useProgressChunks } = this.state;
    const { isSignedIn } = this.props;
    const nsfwCollection = collections.find((c) => c.type === "NsfwCollection");

    if(nsfwCollection) {
      return (
        <CollectionPanel
          className="nsfw-collection"
          collection={nsfwCollection}
          fallbackDescription={"A collection of sentences with swear words, vulgarities, and other NSFW content."}
          isSignedIn={isSignedIn}
          manage={() => this.setState({ managingCollection: nsfwCollection })}
          play={() => this.setState({ aboutToPlayCollection: nsfwCollection })}
          progressPerChunk={progressPerChunk}
          useProgressChunks={useProgressChunks}
        />
      );
    }
  }

  renderPanels() {
    return (
      <div>
        {this.renderAnonPlayPanel()}
        {this.renderMainPanel()}
        {this.renderPanelsAd()}
        {this.renderFrequencyCollectionsPanel()}
        {this.renderMyCollectionsPanel()}
        {this.renderGrammarCollectionsPanel()}
        {this.renderCollectionGroupingsPanel()}
        {this.renderClozeListeningPanel()}
        {this.renderClozeReadingPanel()}
        {this.renderRandomCollection()}
        {this.renderLegacyFastTrack()}
        {this.renderNsfwCollection()}
      </div>
    );
  }

  renderClozeListeningPanel() {
    if(!this.state.playClozeListeningUrl) {
      return null;
    }

    const { 
      clozeListening,
      collections,
      playClozeListeningUrl,
      progressPerChunk,
      useProgressChunks,
      user
    } = this.state;
    const { isSignedIn } = this.props;

    // const clozeListeningCollection = collections.find((c) => c.type === "ClozeListeningCollection");

    // if(clozeListeningCollection) {
    //   return (
    //     <ClozeListeningCollectionComponent
    //       collection={clozeListeningCollection}
    //       isSignedIn={isSignedIn}
    //       manage={() => this.setState({ managingCollection: clozeListeningCollection })}
    //       play={() => this.setState({ aboutToPlayCollection: clozeListeningCollection })}
    //       progressPerChunk={progressPerChunk}
    //       useProgressChunks={useProgressChunks}
    //     />
    //   );
    // }

    const {
      numPlaying,
      numReadyForReview,
      uniqueListeningTimeToday,
      uniqueListeningTimePast7Days,
      uniqueListeningTimePast30Days,
      uniqueListeningTimeTotal
    } = clozeListening;

    if(!numPlaying) {
      return null;
    }

    return (
      <ClozeListeningPanel
        isPro={user.isPro}
        numPlaying={numPlaying}
        numReadyForReview={numReadyForReview}
        uniqueListeningTimeToday={uniqueListeningTimeToday}
        uniqueListeningTimePast7Days={uniqueListeningTimePast7Days}
        uniqueListeningTimePast30Days={uniqueListeningTimePast30Days}
        uniqueListeningTimeTotal={uniqueListeningTimeTotal}
        url={playClozeListeningUrl}
      />
    );
  }

  renderClozeReadingPanel() {
    const { clozeReadingPath, isSignedIn } = this.props;

    if(!clozeReadingPath) {
      return null;
    }

    return (
      <div className="panel panel-default cloze-reading">
        <div className="panel-body">
          <div className="pull-right" style={{ marginTop: 10 }}>
            <i className="fa fa-file-text-o fa-4x" aria-hidden="true" style={{ marginLeft: 4 }}></i>
          </div>
          <h2 className="title">
            Cloze-Reading
            <button className="btn btn-xs btn-link" data-content="<p>Take your literacy to the next level! Practice reading native level content on a variety of topics from Wikipedia, or create your own.</p><p>Multiple paragraphs > multiple sentences > one missing word per sentence > select the correct answer > score points.</p><p>Points are saved after playing all sentences in an article and count towards your total score, level, and streak, but don't affect your overall sentences played, mastered, or reviews.</p>" data-html="true" data-title="Cloze-Reading" data-toggle="popover" data-trigger="focus" style={{ fontSize: '65%', marginLeft: 8, textDecoration: 'none' }}>
              <span className="glyphicon glyphicon-question-sign"></span>
            </button>
            <br className="hidden-xs" />
            <small>
              Level up your reading skills with longer texts.
            </small>
            <a className="btn btn-success joystix" disabled={!isSignedIn} href={clozeReadingPath} style={{ marginLeft: 8 }}>Go
              <span className="glyphicon glyphicon-chevron-right"></span>
            </a>
          </h2>
        </div>
      </div>
    );
  }

  renderAddToCollectionModal() {
    const { addingToCollection, addToCollectionVisible, user } = this.state;

    if(!addToCollectionVisible) {
      return null;
    }

    return (
      <AddToCollectionModal
        collection={addingToCollection}
        collectionsUrl={this.state.collectionsUrl}
        isPro={user.isPro}
        onFile={() => this.addToCollectionModal.hide({ onHidden: () => this.showAddFileToCollectionModal(addingToCollection) })}
        onHidden={() => this.setState({ addingToCollection: null, addToCollectionVisible: false })}
        onManual={() => this.addToCollectionModal.hide({ onHidden: () => this.showAddSentenceToCollectionModal(addingToCollection) })}
        onSearch={() => this.addToCollectionModal.hide({ onHidden: () => this.showSentenceSearchModal(addingToCollection) })}
        onPaste={() => this.addToCollectionModal.hide({ onHidden: () => this.showAddTextToCollectionModal(addingToCollection) })}
        onWords={() => this.addToCollectionModal.hide({ onHidden: () => this.showAddClozeFromWordsToCollectionModal(addingToCollection) })}
        ref={(el) => this.addToCollectionModal = el}
      />
    );
  }

  updateMyCollections() {
    this.setState({ updating: true });
    $.ajax({
      data: { filter: 'mine' },
      url: this.state.collectionsUrl
    })
      .done((data) => {
        const updatedCollectionsHash = data.collections.reduce((h, c) => { h[c.id] = c; return h; }, {});
        let collections = this.state.collections.map((collection) => {
          if(updatedCollectionsHash[collection.id]) {
            const updatedCollection = updatedCollectionsHash[collection.id];
            delete updatedCollectionsHash[collection.id];
            return updatedCollection;
          }
          return collection;
        });
        // add any remaining collections
        collections = collections.concat(Object.values(updatedCollectionsHash));

        this.setState(Object.assign(this.getCollectionsStats(collections), {
          collections,
          updating: false
        }));
      })
      .fail(() => {
        alert('Oh no! There was an error updating the dashboard. Sorry about that. Please refresh the page and let us know if you see this message again.');
        this.setState({ updating: false });
      });
  }

  updateCollection(collection) {
    this.setState({ updating: true });
    $.ajax({
      url: collection.url
    })
      .done((data) => {
        let updated = false;
        const collections = this.state.collections.map((collection) => {
          if(collection.id === data.collection.id) {
            updated = true;
            return data.collection;
          }
          return collection;
        });

        // if it's a new collection
        if(!updated) {
          collections.push(data.collection);
        }

        this.setState(Object.assign(this.getCollectionsStats(collections), {
          collections,
          updating: false
        }));
      })
      .fail(() => {
        alert('Oh no! There was an error updating the dashboard. Sorry about that. Please refresh the page and let us know if you see this message again.');
        this.setState({ updating: false });
      });
  }

  renderManageCollectionModal() {
    const { collectionsUrl, managingCollection, managingCollectionScope, user } = this.state;
    const { baseLanguageEnglishName, targetLanguageCode, targetLanguageEnglishName, targetLanguageIso, targetLanguageName } = this.props;

    if(!managingCollection) {
      return null;
    }

    return (
      <ManageCollectionModal
        baseLanguageEnglishName={baseLanguageEnglishName}
        collectionClozeSentencesUrl={managingCollection.collectionClozeSentencesUrl}
        collectionName={managingCollection.name}
        collectionType={managingCollection.type}
        collectionsUrl={collectionsUrl}
        deleteCollectionProgressUrl={managingCollection.deleteProgressUrl}
        isCollectionEditable={managingCollection.isEditable}
        isPro={user.isPro}
        onHidden={({ hasCopiedSentences }) => {
          const { managingCollection } = this.state;

          this.setState({
            managingCollection: null,
            managingCollectionScope: null
          }, () => {
            // reload the dashboard if copied sentences between collections
            // easier to reload everything vs individual collections
            if(hasCopiedSentences) {
              this.loadLanguagePairing();
              return false;
            }
            this.updateCollection(managingCollection);
          });
        }}
        scope={managingCollectionScope}
        targetLanguageCode={targetLanguageCode}
        targetLanguageEnglishName={targetLanguageEnglishName}
        targetLanguageIso={targetLanguageIso}
        targetLanguageName={targetLanguageName}
      />
    );
  }

  showAddSentenceToCollectionModal(addSentenceToCollection) {
    this.setState({ addSentenceToCollectionVisible: true, addSentenceToCollection });
  }

  onMoreStatsBtnClick() {
    this.setState({ moreStatsVisible: true });
  }

  renderProControls() {
    if(this.state.loading) {
      return null;
    }

    const { isSignedIn } = this.props;

    return (
      <ul className="list-inline pro-controls" style={{ marginRight: -5 }}>
        <li><button className="btn btn-default btn-lg add-sentence-to-collection" disabled={!isSignedIn} onClick={() => this.showAddSentenceToCollectionModal()} data-toggle="tooltip" title="Add sentence to collection"><Icon name="plus" /></button></li>
        {/*= link_to current_user.pro_subscriber? ? more_stats_path(@language_pairing.slug) : pro_signup_path, disabled: !current_user.pro_subscriber?, class: 'btn btn-default btn-lg', data: { toggle: 'tooltip' }, title: 'More stats' do*/}
        <li><button className="btn btn-default btn-lg more-stats" disabled={!isSignedIn} onClick={() => this.onMoreStatsBtnClick()} data-toggle="tooltip" title="More stats"><Icon name="stats" /></button></li>
        {/*= link_to current_user.pro_subscriber? ? sentences_path(@language_pairing.slug) : pro_signup_path, disabled: !current_user.pro_subscriber?, class: 'btn btn-default btn-lg', data: { toggle: 'tooltip' }, title: 'Search sentences' do*/}
        <li><button id="search-sentences" disabled={!isSignedIn} onClick={() => this.onSearchBtnClick()} className="btn btn-default btn-lg" data-toggle="tooltip" title="Search sentences"><Icon name="search" /></button></li>
        {/*= link_to current_user.pro_subscriber? ? settings_path(@language_pairing.slug) : pro_signup_path, disabled: !current_user.pro_subscriber?, class: 'btn btn-default btn-lg', data: { toggle: 'tooltip', html: true }, title: '<span style="white-space: nowrap;">Review settings</span>' do*/}
        <li><button onClick={() => this.onReviewSettingsBtnClick()} disabled={!isSignedIn} className="btn btn-default btn-lg review-settings" data-toggle="tooltip" data-html="true" title="<span style='white-space: nowrap;'>Review settings</span>"><Icon name="time" /></button></li>
      </ul>
    );
  }

  onSearchBtnClick() {
    this.showSentenceSearchModal();
  }

  showSentenceSearchModal(addSentenceToCollection) {
    // TODO! if not pro, show pro promo
    this.setState({ addSentenceToCollection, sentenceSearchModalVisible: true });
  }

  onReviewSettingsBtnClick() {
    // TODO! if not pro, show pro promo
    this.setState({ reviewSettingsModalVisible: true });
  }

  renderDailyReminder() {
    return (
      <DailyReminderPanel
        dailyReminderEmail={this.state.dailyReminderEmail}
        isSignedIn={this.props.isSignedIn}
        timeZone={this.props.timeZone}
        updateUrl={this.state.dailyReminderEmailUrl}
      />
    );
  }

  renderDailyGoal() {
    const { isSignedIn, targetLanguageName, timeZone } = this.props;
    const {
      dailyGoalHoursLeftToday,
      dailyGoalPointsPerDay,
      dailyGoalStreak,
      dailyGoalUrl,
      numPointsToday
    } = this.state;

    return (
      <DailyGoalPanel
        hoursLeftToday={dailyGoalHoursLeftToday}
        isSignedIn={isSignedIn}
        numPointsToday={numPointsToday}
        pointsPerDay={dailyGoalPointsPerDay}
        streak={dailyGoalStreak}
        targetLanguageName={targetLanguageName}
        timeZone={timeZone}
        updateUrl={dailyGoalUrl}
      />
    );
  }

  renderProPromo() {
    if(this.state.user.isPro) {
      return null;
    }

    return (
      <div className="panel panel-default" style={{ background: "#464646", color: "#fff" }}>
        <div className="panel-body text-center">
          <h5 style={{ fontSize: 24, marginTop: 0 }}>Get fluent faster.</h5>
          <a href="/pro" className="btn joystix btn-block btn-attention">
            Clozemaster Pro
            <Icon name="chevron-right" className="hidden-md" />
          </a>
        </div>
      </div>
    );
  }

  renderGiftPro() {
    if(this.props.giftsDisabled) {
      return null;
    }

    return (
      <div className="panel panel-default gift-pro clozemaster">
        <div className="panel-heading joystix">
          Gift Pro!
        </div>
        <div className="panel-body text-center">
          <p style={{ fontSize: '1.25em' }}>Send Clozemaster Pro as a gift and get a free month!</p>
          <a className="btn btn-attention joystix btn-block" href="/gift-pro">Learn More
            <span className="glyphicon glyphicon-chevron-right"></span>
          </a>
        </div>
      </div>
    );
  }

  renderLatestBlogPost() {
    return (
      <div className="panel panel-default clozemaster">
        <div className="panel-heading joystix">
          Blog
        </div>
        <div className="panel-body text-center">
          <strong>Latest blog post:</strong>
          <div style={{ fontSize: '1.25em' }}>
            <a target="_blank" href={this.props.latestBlogPostUrl}>{this.props.latestBlogPostTitle}</a>
          </div>
        </div>
      </div>
    );
  }

  renderChallengeFriends() {
    return null;

    // // just skipping the challenge friends if anon for now
    // // should probably add some kind of way to share later
    // // or improve challenge friend so you follow them automatically?
    // if(!this.props.isSignedIn) {
    //   return null;
    // }

    // return (
    //   <ChallengeFriendsPanel
    //     friendInvitesUrl={this.props.friendInvitesUrl}
    //     isSignedIn={this.props.isSignedIn}
    //   />
    // );
  }

  renderFollowClozemaster() {
    return (
      <FollowClozemasterPanel />
    );
  }

  renderPlayOptionsModal() {
    const { aboutToPlayCollection, aboutToPlayScope, collections, listeningTrialExpired, playOptions, speakingTrialExpired, user } = this.state;
    const { isSignedIn, isSpeakingSkillAvailable, targetLanguageCode, targetLanguageIso, translateTranscribeAvailable } = this.props;

    if(!aboutToPlayCollection) {
      return null;
    }

    let isListeningSkillAvailable = false;
    if(aboutToPlayCollection.ttsAvailable) {
      isListeningSkillAvailable = true;
    }
    else if(aboutToPlayCollection.name === 'All') {
      if(aboutToPlayScope === 'ready_for_review') {
        isListeningSkillAvailable = collections.filter((c) => c.numReadyForReview).every((c) => c.ttsAvailable);
      }
      else if(aboutToPlayScope === 'favorited') {
        isListeningSkillAvailable = collections.filter((c) => c.numFavorited).every((c) => c.ttsAvailable);
      }
    }
    // allow pro users to use system tts as well
    isListeningSkillAvailable = isListeningSkillAvailable || 
      (user.isPro && window.clozemaster.systemTtsAvailable(targetLanguageIso, targetLanguageCode));

    return (
      <PlayOptionsModal
        collection={aboutToPlayCollection}
        isPro={user.isPro}
        isListeningSkillAvailable={isListeningSkillAvailable}
        isSignedIn={isSignedIn}
        isSpeakingSkillAvailable={isSpeakingSkillAvailable}
        listeningTrialExpired={listeningTrialExpired}
        onHidden={() => this.setState({ aboutToPlayCollection: null, aboutToPlayScope: null })}
        playOptions={playOptions}
        scope={aboutToPlayScope}
        speakingTrialExpired={speakingTrialExpired}
        targetLanguageIso={targetLanguageIso}
        translateTranscribeAvailable={translateTranscribeAvailable}
      />
    );
  }

  renderPast7DaysChart() {
    const { dailyStatsUrl, user } = this.state;
    const { isSignedIn } = this.props;

    return (
      <div className="text-center" style={{ clear: "both", position: "relative" }}>
        <canvas ref={(el) => this.past7DaysCanvas = el} style={{ width: '95%', height: 100, margin: '10px 0' }} />
        <FullHistoryBtn 
          dailyStatsUrl={dailyStatsUrl}
          disabled={!isSignedIn}
          style={{ position: "absolute", right: 0, top: 10 }}
          user={user}
        />
      </div>
    );
  }

  renderReviewSettingsModal() {
    if(!this.state.reviewSettingsModalVisible) {
      return null;
    }

    return (
      <ReviewSettingsModal
        isPro={this.state.user.isPro}
        onHidden={() => this.setState({ reviewSettingsModalVisible: false }, () => this.loadLanguagePairing())}
        url={this.state.reviewSettingsUrl}
      />
    );
  }

  renderMoreStatsModal() {
    if(!this.state.moreStatsVisible) {
      return null;
    }

    return (
      <MoreStatsModal
        isPro={this.state.user.isPro}
        moreStatsUrl={this.state.moreStatsUrl}
        onHidden={() => this.setState({ moreStatsVisible: false })}
      />
    );
  }

  showAddClozeFromWordsToCollectionModal(addSentencesToCollection) {
    this.setState({
      addSentencesToCollection,
      addClozeFromWordsToCollectionModalVisible: true,
    });
  }

  showAddTextToCollectionModal(addSentencesToCollection) {
    this.setState({
      addSentencesToCollection,
      addTextToCollectionModalVisible: true,
    });
  }

  showAddFileToCollectionModal(addSentencesToCollection) {
    this.setState({
      addSentencesToCollection,
      addFileToCollectionModalVisible: true,
    });
  }

  renderAddClozeFromWordsToCollectionModal() {
    const { addSentencesToCollection, addClozeFromWordsToCollectionModalVisible, collectionsUrl, clozeFromWordsUrl, user } = this.state;

    if(!addClozeFromWordsToCollectionModalVisible) {
      return null;
    }

    return (
      <AddClozeFromWordsToCollectionModal
        collection={addSentencesToCollection}
        isPro={user.isPro}
        onHidden={() => this.setState({ addSentencesToCollection: null, addClozeFromWordsToCollectionModalVisible: false })}
        onSentencesAdded={() => {
          this.updateCollection(addSentencesToCollection);
        }}
        clozeFromWordsUrl={clozeFromWordsUrl}
        ref={(el) => this.addClozeFromWordsToCollectionModal = el}
      />
    );
  }

  renderAddTextToCollectionModal() {
    const { addSentencesToCollection, addTextToCollectionModalVisible, collectionsUrl, quickClozeUrl, user } = this.state;

    if(!addTextToCollectionModalVisible) {
      return null;
    }

    return (
      <AddTextToCollectionModal
        collection={addSentencesToCollection}
        isPro={user.isPro}
        onHidden={() => this.setState({ addSentencesToCollection: null, addTextToCollectionModalVisible: false })}
        onSentencesAdded={() => {
          this.updateCollection(addSentencesToCollection);
        }}
        quickClozeUrl={quickClozeUrl}
        ref={(el) => this.addTextToCollectionModal = el}
      />
    );
  }

  renderAddFileToCollectionModal() {
    const { addSentencesToCollection, addFileToCollectionModalVisible, collectionsUrl, collectionFileImportUrl, user } = this.state;

    if(!addFileToCollectionModalVisible) {
      return null;
    }

    return (
      <AddFileToCollectionModal
        collection={addSentencesToCollection}
        collectionFileImportUrl={collectionFileImportUrl}
        isPro={user.isPro}
        onHidden={() => this.setState({ addSentencesToCollection: null, addFileToCollectionModalVisible: false })}
        onSentencesAdded={() => {
          this.updateCollection(addSentencesToCollection);
        }}
        ref={(el) => this.addFileToCollectionModal = el}
      />
    );
  }

  renderSentenceSearchModal() {
    if(!this.state.sentenceSearchModalVisible) {
      return null;
    }

    return (
      <ClozeSentenceSearchModal 
        collectionsUrl={this.state.collectionsUrl}
        isPro={this.state.user.isPro}
        initialSelectedCollectionId={this.state.addSentenceToCollection && this.state.addSentenceToCollection.id}
        nextReviewByLevel={this.state.nextReviewByLevel}
        onHidden={() => {
          this.updateMyCollections();
          this.setState({ sentenceSearchModalVisible: false, addSentenceToCollection: null });
        }}
        url={this.state.clozeSentencesUrl}
      />
    );
  }

  renderCollectionEditorModal() {
    if(!this.state.collectionEditorModalVisible) {
      return null;
    }

    const {
      collectionEditorCollection,
      collectionsUrl,
      user
    } = this.state;

    return (
      <CollectionEditorModal
        collection={collectionEditorCollection}
        collectionsUrl={collectionsUrl}
        isPro={user.isPro}
        onCreate={(collection) => {
          const collections = JSON.parse(JSON.stringify(this.state.collections));
          collections.unshift(collection);
          this.setState({ collections });
        }}
        onDelete={(collection) => {
          const collections = JSON.parse(JSON.stringify(this.state.collections));
          for(let i = 0, n = collections.length; i < n; i++) {
            if(collections[i].id === collection.id) {
              collections.splice(i, 1);
              break;
            }
          }
          this.setState({ collections });
        }}
        onHidden={() => {
          this.setState({
            collectionEditorCollection: null,
            collectionEditorModalVisible: false
          });
        }}
        onUpdate={(collection) => {
          const collections = JSON.parse(JSON.stringify(this.state.collections));
          for(let i = 0, n = collections.length; i < n; i++) {
            if(collections[i].id === collection.id) {
              collections[i] = collection;
              break;
            }
          }
          this.setState({ collections });
        }}
      />
    );
  }

  renderAddSentenceToCollectionModal() {
    if(!this.state.addSentenceToCollectionVisible) {
      return null;
    }

    return (
      <AddSentenceToCollectionModal
        collectionsUrl={this.state.collectionsUrl}
        initialSelectedCollectionId={this.state.addSentenceToCollection && this.state.addSentenceToCollection.id}
        isPro={this.state.user.isPro}
        nextReviewByLevel={this.state.nextReviewByLevel}
        onHidden={() => this.setState({ addSentenceToCollectionVisible: false, addSentenceToCollection: null })}
        onSentenceAdded={(collectionClozeSentence, collection) => {
          this.updateCollection(collection);
          this.addSentenceToCollectionModal.reset();
          // this.addSentenceToCollectionModal.hide();
        }}
        ref={(el) => this.addSentenceToCollectionModal = el}
      />
    );
  }

  renderCollectionTypeModal() {
    const { collectionTypeModal, collections } = this.state;

    if(collectionTypeModal) {
      const readableCollectionType = this.readableCollectionType(collectionTypeModal);
      return (
        <Modal
          footer={<ModalFooterCloseBtn text="Close" />}
          onHidden={() => this.setState({ collectionTypeModal: null })}
          ref={(el) => this.collectionTypeModalRef = el}
          title={`${readableCollectionType} Progress`}
        >
          {collections.filter((c) => c.type === collectionTypeModal).map((c) => (
            <div>
              <p className="tw-flex tw-flex-row tw-justify-between"><strong>{c.name}</strong> {c.numSentences.toLocaleString()} sentences</p>
              <CollectionProgressBars
                collection={c}
              />
            </div>
          ))}
          <button
            className="btn btn-success joystix btn-block"
            onClick={() => {
              this.setState({ activeTab: "collections" }, () => {
                this.collectionTypeModalRef && this.collectionTypeModalRef.hide();
                const e = document.querySelector(`[data-type="${collectionTypeModal}"]`);
                if(e) {
                  e.scrollIntoView({ behavior: "smooth" });
                }
                if(!e.classList.contains("open")) {
                  e.querySelector(".btn.toggle-open").click();
                }
              }); 
            }}
          >
            Go to <span className="tw-hidden md:tw-inline">{readableCollectionType} </span>Collections
          </button>
        </Modal>
      );
    }
  }

  renderCollectionReviewSettingsModal() {
    const { collectionReviewSettingsVisible } = this.state;

    if(!collectionReviewSettingsVisible) { 
      return null;
    }

    console.log("collectionReviewSettingsVisible", collectionReviewSettingsVisible);

    const { user } = this.state;
    console.log("user", user);

    return (
      <CollectionReviewSettingsModal
        collectionName={collectionReviewSettingsVisible.name}
        isPro={user.isPro}
        onHidden={() => this.setState({ collectionReviewSettingsVisible: false }, () => this.loadLanguagePairing())}
        url={""}
      />
    );
  }

  renderModals() {
    return (
      <>
        {this.renderPlayOptionsModal()}
        {this.renderManageCollectionModal()}
        {this.renderAddToCollectionModal()}
        {this.renderAddSentenceToCollectionModal()}
        {this.renderAddTextToCollectionModal()}
        {this.renderAddClozeFromWordsToCollectionModal()}
        {this.renderAddFileToCollectionModal()}
        {this.renderCollectionEditorModal()}
        {this.renderCollectionReviewSettingsModal()}
        {this.renderCollectionTypeModal()}
        {this.renderFavoritesModal()}
        {this.renderDownloadFavoritesModal()}
        {this.renderReviewSettingsModal()}
        {this.renderSentenceSearchModal()}
        {this.renderMoreStatsModal()}
        {this.renderLanguagePairingSettingsModal()}
        {this.renderDeleteLanguagePairingModal()}
        {this.renderResetProgressModal()}
      </>
    );
  }

  renderResetProgressModal() {
    const {
      baseLanguageFlagIso,
      baseLanguageName,
      targetLanguageFlagIso,
      targetLanguageName
    } = this.props;

    const { collections, resetProgressUrl } = this.state;

    return (
      <ResetProgressModal
        baseLanguageFlagIso={baseLanguageFlagIso}
        baseLanguageName={baseLanguageName}
        collections={collections.filter((c) => c.playing)}
        resetProgressUrl={resetProgressUrl}
        onResetLanguagePairing={() => this.setState({ updating: true })}
        onResetCollection={() => this.setState({ updating: true })}
        targetLanguageFlagIso={targetLanguageFlagIso}
        targetLanguageName={targetLanguageName}
      />
    );
  }

  renderDeleteLanguagePairingModal() {
    const {
      baseLanguageFlagIso,
      baseLanguageName,
      languagePairingUrl,
      targetLanguageFlagIso,
      targetLanguageName
    } = this.props;

    return (
      <DeleteLanguagePairingModal
        baseLanguageFlagIso={baseLanguageFlagIso}
        baseLanguageName={baseLanguageName}
        languagePairingUrl={languagePairingUrl}
        onDeleteLanguagePairing={() => this.setState({ updating: true })}
        targetLanguageFlagIso={targetLanguageFlagIso}
        targetLanguageName={targetLanguageName}
      />
    );
  }

  renderLanguagePairingSettingsModal() {
    const {
      baseLanguageFlagIso,
      baseLanguageName,
      languagePairingUrl,
      targetLanguageFlagIso,
      targetLanguageName
    } = this.props;

    const {
      gameSettingsUrl,
      progressPerChunk,
      useProgressChunks
    } = this.state;

    return (
      <LanguagePairingSettingsModal
        baseLanguageFlagIso={baseLanguageFlagIso}
        baseLanguageName={baseLanguageName}
        gameSettingsUrl={gameSettingsUrl}
        languagePairingUrl={languagePairingUrl}
        onProgressChunksChange={({ progressPerChunk, useProgressChunks }) => {
          this.setState({
            progressPerChunk,
            useProgressChunks
          });
        }}
        progressPerChunk={progressPerChunk}
        targetLanguageFlagIso={targetLanguageFlagIso}
        targetLanguageName={targetLanguageName}
        useProgressChunks={useProgressChunks}
      />
    );
  }

  renderControls() {
    const { isSignedIn } = this.props;

    return (
      <div className="text-right">
        <ul className="list-inline">
          <li>
            <button className="btn btn-default" disabled={!isSignedIn} id="manage-language-pairing-btn" data-toggle="modal" data-target="#manage-language-pairing-modal">
              <span className="glyphicon glyphicon-cog"></span>
            </button>
          </li>
          <li>
            <button className="btn btn-danger" disabled={!isSignedIn} id="reset-progress-btn" data-toggle="modal" data-target="#reset-progress-modal">
              <span className="glyphicon glyphicon-erase"></span>
            </button>
          </li>
          <li>
            <button className="btn btn-danger" disabled={!isSignedIn} id="delete-language-pairing-btn" data-toggle="modal" data-target="#delete-language-pairing-modal">
              <span className="glyphicon glyphicon-trash"></span>
            </button>
          </li>
        </ul>
      </div>
    );
  }

  renderOnboarding() {
    if(true) {
      return null;
    }

    return (
      <Onboarding
        elements={[
          { selector: null, text: "Clozemaster takes a simple concept - a fill-in-the-blank exercise, otherwise known as \"cloze\" exercise - and takes it to the extreme to help you get fluent faster." },
          { selector: ".panel.fast-track", text: "The Fast Track is where most users start - play through N sentences in order of difficulty, one sentence per missing word." }
    //       { selector: "", text: "The Most Common Words collections let you get extra practice at any point if the Fast Track becomes too difficult." },
    //       { selector: "", text: "You score points for each correct answer, and level up to score more points. Play each day to keep your streak alive, and check out the leaderboards to compete with other players." },

    //       { selector: "", text: "And you level up as you score more points." },
    //       { selector: "", text: "Sentences come up for review to help you remember them better. The number of sentences ready for review is shown here." },
    //       { selector: "", text: "Stuck on grammar? Grammar Challenges are collections of sentences selected to help practice tricky grammar and parts-of-speech." },
    //       { selector: "", text: "Want to customize your learning? 
        ]}
      />
    );
  }

  renderMainContent() {
    if(this.state.loading) {
      return <Loading />;
    }

    return (
      <FadeIn>
        {this.renderStats()}
        {/*this.renderPast7DaysChart()*/}
        {this.renderPanels()}
        {this.renderControls()}
        {this.renderModals()}
        {this.renderOnboarding()}
      </FadeIn>
    );
  }

  renderUpdatingOverlay() {
    if(!this.state.updating) {
      return null;
    }

    return <LoadingOverlay />;
  }

  renderRightColumn() {
    if(this.state.loading) {
      return null;
    }

    return (
      <div className="col-xs-12 col-md-3 right-column">
        {this.renderProPromo()}
        {this.renderDailyReminder()}
        {this.renderDailyGoal()}
        {this.renderGiftPro()}
        {this.renderLatestBlogPost()}
        {this.renderRightColumnAd()}
        {this.renderChallengeFriends()}
        {this.renderFollowClozemaster()}
        {this.renderUpdatingOverlay()}
      </div>
    );
  }

  renderRightColumnAd() {
    const { user } = this.state;
    const { isPro } = user;
    const { googleAdsEnabled } = this.props;

    if(isPro || !googleAdsEnabled) {
      return null;
    }

    return (
      <GoogleAdPanel>
        <GoogleRightSidebarAd />
      </GoogleAdPanel>
    );

    // const { rightColumnAd } = this.props;

    // if(isPro || !rightColumnAd) {
    //   return null;
    // }

    // return (
    //   <Ad
    //     ad={rightColumnAd}
    //     className="right-column-vert"
    //   />
    // );
  }

  renderPanelsAd() {
    const { user } = this.state;
    const { isPro } = user;
    const { googleAdsEnabled } = this.props;

    if(isPro || !googleAdsEnabled) {
      return null;
    }

    return (
      <GoogleAdPanel>
        <GooglePanelsAd />
      </GoogleAdPanel>
    );

    // const { panelsAd } = this.props;

    // if(isPro || !panelsAd) {
    //   return null;
    // }

    // return (
    //   <Ad
    //     ad={panelsAd}
    //     className="panels-vert"
    //   />
    // );
  }

  renderAnonUserPromo() {
    if(this.props.isSignedIn) {
      return null;
    }

    return (
      <div
        className="joystix"
        style={{
          background: '#464646',
          color: '#fff',
          left: 0,
          padding: '10px 15px',
          position: 'fixed',
          right: 0,
          textAlign: 'center',
          top: 0,
          zIndex: 994
        }}
      >
        <div className="hidden-xs" style={{ left: 10, lineHeight: 1, position: 'absolute', top: 2 }}><a href="/" style={{ color: '#fff', fontSize: '3em' }}>M</a></div>
        Get in the game! <a className="btn btn-success" href="/sign-up">Sign up</a> or <a className="btn btn-success" href="/login">Login</a>
      </div>
    );
  }


  readableCollectionType(type) {
    switch(type) {
      case "FrequencyCollection": 
        return "Most Common Words";
      case "FastTrackV2Collection":
        return "Fluency Fast Track";
      case "GrammarCollection":
        return "Grammar Challenge";
      default:
        return "";
    }
  }

  // daily bonus
  // - on language pairing show, find or create for date for lp, 
  // - mode = listening, vocabulary (eventually speaking once merged in)
  // - skill = multiple choice, text input
  // - collection? cloze-reading? radio?

  //----------------------------------
  // X level
  // X leaderboard
  // X favorites
  // X username menu
  // X language pairings menu
  // X hook up search + add
  // X review settings visible but disabled for non pro
  // X state if no collections being played
  // X collection dropdown
  // X reset progress
  // X delete language pairing
  // X stretch to fill page
  // X dark mode
  // X dark mode as user / global setting! NOT PER LP
  // X mobile view dark mode
  // X collections list pin to dashboard in dropdown, simple for mobile view
  // X mobile dashboard buttons overlap fixed bottom nav
  // O daily bonus (including on round results)
  // X left sidebar pro promo, blog, social
  // X daily bonus sub for now - played today?
  // X how are collections ordered on dashboard? probably alphabetical? or last played? but then jumpy? custom order?
  // X mobile width progress bar on separate line
  // X collection design if pro only and not pro
  // X streak days played toggle (including on profile and round results)
  // X mobile viewport
  // - collections, stats, cloze-reading
  // - migrate cloze-listening
  // - smoke test
  // - move languages dropdown to right
  // - confirm email notification
  // - sale notification
  // - fftv2 notification
  // X marked as known count

  renderActiveTab() {
    const {
      activeTab
    } = this.state;

    if(activeTab === "collections") {
      const {
        collections,
        collectionGroupings,
        user
      } = this.state;

      const f = (t) => collections.filter(({ type }) => type === t);
      const clozeListeningCollections = f("ClozeListeningCollection");
      const core1kCollection = f("Core1kCollection");
      const customCollections = f(null);
      const fastTrackCollection = f("FastTrackCollection");
      const fastTrackV2Collections = f("FastTrackV2Collection");
      const frequencyCollections = f("FrequencyCollection");
      const grammarCollections = f("GrammarCollection");
      const nsfwCollection = f("NsfwCollection");
      const randomCollection = f("RandomCollection");
      // what a mess
      const otherCollections = collections.filter(({ type }) => (
        type && ![
          "ClozeListeningCollection",
          "Core1kCollection",
          "FastTrackCollection",
          "FastTrackV2Collection",
          "FrequencyCollection",
          "GrammarCollection",
          "NsfwCollection",
          "RandomCollection"
        ].includes(type)
      )).sort((a, b) => a.name.localeCompare(b.name));

      const fastTrackProps = fastTrackCollection.length ? {
        collections: fastTrackCollection,
        description: `Play ${fastTrackCollection[0].numSentences.toLocaleString()} sentences with ${fastTrackCollection[0].numSentences.toLocaleString()} unique cloze-words in order of difficulty. Fast and efficient language learning. Each word in each sentence is matched against a frequency list (a list of words ordered by how often they're likely to occur). The most difficult (least common) word is then selected as the missing word. The Fast Track has one sentence for each missing word, and the sentences are played in order of difficulty, so you're always making progress.`,
        name: fastTrackCollection.name
      } : {};

      const panels = [];

      if(fastTrackV2Collections.length) {
        panels.push({
          collections: fastTrackV2Collections,
          collectionType: "FastTrackV2Collection",
          description: "Expand your vocabulary quickly and efficiently - play through 10 collections in order of difficulty, from sentences with more common vocabulary to sentences with less common vocabulary.",
          first: true,
          name: "Fluency Fast Track"
        });
      }
      else if(fastTrackCollection.length) {
        panels.push(Object.assign({}, fastTrackProps, { first: true }));
      }

      panels.push({
        collections: frequencyCollections,
        collectionType: "FrequencyCollection",
        first: !fastTrackV2Collections.length && !fastTrackCollection.length,
        name: "Most Common Words"
      });

      panels.push({
        collections: core1kCollection,
        name: core1kCollection.name
      });

      panels.push({
        collections: grammarCollections,
        collectionType: "GrammarCollection",
        name: "Grammar Challenges"
      });

      collectionGroupings.forEach((grouping) => {
        panels.push({
          collections: collections.filter((c) => c.collectionGroupingId === grouping.id),
          name: grouping.name
        });
      });
      
      if(otherCollections.length) {
        otherCollections.forEach((c) => panels.push({
          collections: [c],
          name: c.name
        }));
      }

      panels.push({
        collections: nsfwCollection,
        name: "NSFW Collection"
      });

      if(fastTrackV2Collections.length && fastTrackCollection.length) {
        panels.push(fastTrackProps);
      }

      panels.push({
        collections: randomCollection,
        description: "A collection of randomly selected sentences, played in random order.",
        name: randomCollection.name
      });

      panels.push({
        collections: customCollections,
        customCollectionsPanel: true,
        description: "Play shared collections of cloze sentences or create your own!",
        name: "Custom Collections"
      });

      return (
        <>
          {panels.map((props, i) => (
            <CollectionsPanel
              {...props}
              isPro={user.isPro}
              key={i}
              manage={(collection) => this.setState({ managingCollection: collection })}
              play={({ collection, scope = null }) => this.setState({ aboutToPlayCollection: collection, aboutToPlayScope: scope })}
              sharedCollectionsUrl={this.state.sharedCollectionsUrl}
              showAddToCollectionModal={(collection) => this.showAddToCollectionModal(collection)}
              showCollectionEditor={(collection) => this.showCollectionEditor(collection)}
              totalNumPlaying={collections.reduce((t, c) => t + c.numPlaying, 0)}
              update={(collection) => this.setState({ collections: collections.map((c) => c.id === collection.id ? collection : c) })}
            />
          ))}
          {this.renderClozeListeningPanel()}
        </>
      );
    }

    const {
      baseLanguageFlagIso,
      languagePairingUrl,
      targetLanguageFlagIso
    } = this.props;

    const {
      activeMobilePanels,
      collections,
      currentLevelPoints,
      currentStreakDays,
      currentStreakIncludesToday,
      currentWeekLeaderboardRank,
      dailyGoalHoursLeftToday,
      dailyGoalPointsPerDay,
      dailyGoalUrl,
      dailyReminderEmail,
      dailyReminderEmailUrl,
      dailyStatsUrl,
      daysPlayedCount,
      gameSettingsUrl,
      level,
      maxReviewsPerRound,
      nextLevelPoints,
      numFavorited,
      numMastered,
      numPlaying,
      numPointsToday,
      numReadyForReview,
      past7DaysNewReview,
      playLanguagePairingWebUrl,
      rankingsUrl,
      showDaysPlayedInsteadOfStreak,
      score,
      user
    } = this.state;

    return (
      <>
        <div className="tw-grid tw-grid-cols-2 md:tw-grid-cols-4 tw-gap-4 tw-auto-rows-fr">
          <Panel className={`tw-mb-0 ${activeMobilePanels === 0 ? "tw-hidden" : ""} md:tw-block`} bodyClassName="tw-p-4">
            <div className="tw-font-bold tw-uppercase">Score</div>
            <div className="joystix tw-text-4xl tw-my-2.5">{score.toLocaleString()}</div>
            <div className="tw-flex tw-flex-row tw-flex-wrap">
              <div className="tw-mr-4"><PlayingIcon /> Playing: <strong>{numPlaying.toLocaleString()}</strong></div>
              <div><MasteredIcon /> Mastered: <strong>{numMastered.toLocaleString()}</strong></div>
            </div>
          </Panel>
          <ReviewPanel
            activeMobilePanels={activeMobilePanels}
            collections={collections}
            numReadyForReview={numReadyForReview}
            onPlay={(collection) => this.setState({ aboutToPlayCollection: collection, aboutToPlayScope: 'ready_for_review' })}
            onReviewSettingsBtnClick={this.onReviewSettingsBtnClick.bind(this)}
            playLanguagePairingUrl={playLanguagePairingWebUrl}
          />
          <StreakPanel
            activeMobilePanels={activeMobilePanels}
            currentStreakDays={currentStreakDays}
            currentStreakIncludesToday={currentStreakIncludesToday}
            dailyReminderEmail={dailyReminderEmail}
            dailyReminderEmailUrl={dailyReminderEmailUrl}
            daysPlayedCount={daysPlayedCount}
            languagePairingUrl={languagePairingUrl}
            onShowDaysPlayedInsteadOfStreakChange={(showDaysPlayedInsteadOfStreak) => this.setState({ showDaysPlayedInsteadOfStreak })}
            showDaysPlayedInsteadOfStreak={showDaysPlayedInsteadOfStreak}
            timeZone={this.props.timeZone}
          />
          <V3DailyGoalPanel
            activeMobilePanels={activeMobilePanels}
            hoursLeft={dailyGoalHoursLeftToday}
            numPointsToday={numPointsToday}
            pointsPerDay={dailyGoalPointsPerDay}
            updateUrl={dailyGoalUrl}
          />
          {/*
          // <Panel className={`tw-mb-0 ${activeMobilePanels === 1 ? "tw-hidden" : ""} md:tw-block`} bodyClassName="tw-p-4">
          //   <div className="tw-flex tw-flex-row tw-justify-between">
          //     <span className="tw-font-bold tw-uppercase">Daily Bonus</span>
          //     <span data-toggle="tooltip" title="Play a new challenge each day to score extra points."><Icon name="question-sign" /></span>
          //   </div>
          //   <div className="tw-my-2.5">
          //     <span className="joystix"><span className="tw-text-4xl">0</span> / 20</span> sentences
          //   </div>
          //   <div><strong>Listening</strong> <Icon name="arrow-right" /> <strong>500 points</strong></div>
          // </Panel>
          */}
          <Panel className={`tw-mb-0 ${activeMobilePanels === 1 ? "tw-hidden" : ""} md:tw-block`} bodyClassName="tw-p-4">
            <div className="tw-flex tw-flex-row tw-justify-between">
              <span className="tw-font-bold tw-uppercase">Played Today</span>
            </div>
            <div className="tw-my-2.5">
              <span className="joystix tw-text-4xl">{past7DaysNewReview[6].numNew + past7DaysNewReview[6].numReview}</span> sentences
            </div>
            <div><strong>{past7DaysNewReview[6].numNew}</strong> New / <strong>{past7DaysNewReview[6].numReview}</strong> Review</div>
            <div className="sm:tw-hidden">
              <FullHistoryBtn 
                dailyStatsUrl={dailyStatsUrl}
                user={user}
              />
            </div>
          </Panel>
          <Panel className={`tw-mb-0 ${activeMobilePanels === 0 ? "tw-hidden" : ""} md:tw-block`} bodyClassName="tw-p-4">
            <div className="tw-flex tw-flex-row tw-justify-between">
              <span className="tw-font-bold tw-uppercase">Level</span> 
              <span data-toggle="tooltip" title="Score more points to level up">
                <Icon name="question-sign" />
              </span>
            </div>
            <div className="joystix tw-my-2.5 tw-text-4xl">{level.toLocaleString()}</div>
            <div className="progress" style={{ height: 10, margin: 0 }}>
              <div className="progress-bar progress-bar-success" style={{ width: (((score - currentLevelPoints) / (nextLevelPoints - currentLevelPoints)) * 100) + "%" }}></div>
            </div>
            <div>{(nextLevelPoints - score).toLocaleString()} more points to level {level + 1}</div>
          </Panel>
          <LeaderboardPanel
            activeMobilePanels={activeMobilePanels}
            baseLanguageFlagIso={baseLanguageFlagIso}
            currentWeekLeaderboardRank={currentWeekLeaderboardRank}
            rankingsUrl={rankingsUrl}
            targetLanguageFlagIso={targetLanguageFlagIso}
          />
          <Panel className={`tw-mb-0 ${activeMobilePanels === 0 ? "tw-hidden" : ""} md:tw-block`} bodyClassName="tw-p-4">
            <div className="tw-font-bold tw-uppercase">Favorites</div>
            <div className="joystix tw-my-2.5 tw-text-4xl">{numFavorited.toLocaleString()}</div>
            <div className="tw-flex tw-flex-row tw-gap-x-2">
              <button className="btn btn-default joystix btn-xs tw-flex-1" disabled={!numFavorited} onClick={() => this.openFavoritesModal("view")}>View</button>
              <button className="btn btn-success-outline joystix btn-xs tw-flex-1" disabled={!numFavorited} onClick={() => this.openFavoritesModal("play")}>Play</button>
            </div>
          </Panel>
        </div>

        <div className="tw-my-2 tw-hidden md:tw-block">{this.renderPast7DaysChart()}</div>

        <div className="md:tw-hidden tw-mt-6 tw-mb-4 tw-flex tw-flex-row tw-justify-center tw-gap-5">
          <button
            className="btn btn-default btn-sm"
            disabled={activeMobilePanels === 0}
            onClick={() => this.setState({ activeMobilePanels: 0 })}
          >
            <Icon name="chevron-left" />
          </button>
          <button
            className="btn btn-default btn-sm"
            disabled={activeMobilePanels === 1}
            onClick={() => this.setState({ activeMobilePanels: 1 })}
          >
            <Icon name="chevron-right" />
          </button>
        </div>

        <div>
          <p className="joystix">In Progress</p>

          {!collections.filter((c) => c.dashboardCollection).length && (
            <div className="tw-flex tw-flex-col tw-items-center tw-my-20">
              <div className="tw-mb-10 tw-text-5xl tw-font-bold tw-text-center">Choose a collection and get in the game!</div>
              <button className="btn btn-success btn-lg joystix" onClick={() => this.setState({ activeTab: "collections" })}>Start <Icon name="chevron-right" /></button>
            </div>
          )}

          {collections.filter((c) => c.dashboardCollection).sort(collectionNameSort).map((c) => (
            <Panel>
              <div className="tw-flex tw-flex-row tw-mb-5 tw-items-start">
                <div className="md:tw-flex md:tw-flex-row tw-flex-1 md:tw-items-center md:tw-gap-4">
                  <div className="tw-text-5xl tw-font-bold">{c.name}</div>
                  <div>{c.numSentences.toLocaleString()} Sentences</div>
                  {this.readableCollectionType(c.type) && (
                    <div>
                      <button
                        className="btn btn-success-outline btn-xs"
                        onClick={() => this.setState({ collectionTypeModal: c.type })}
                      >
                        {this.readableCollectionType(c.type)}
                      </button>
                    </div>
                  )}
                </div>
                <DropDownMenu
                  button={
                    <button className="btn btn-default btn-sm" data-toggle="dropdown">
                      <Icon name="option-horizontal" />
                    </button>
                  }
                  lis={
                    <>
                      {c.isEditable && <li><a data-target="#" role="button" onClick={() => this.showAddToCollectionModal(c)}><Icon name="plus" /><span className="tw-ml-5">Add Sentences</span></a></li>}
                      <li><a data-target="#" role="button" onClick={() => this.setState({ managingCollection: c })}><Icon name="list" /><span className="tw-ml-5">Manage Sentences</span></a></li>
                      {/*<li><a data-target="#" role="button" onClick={() => this.setState({ collectionReviewSettingsVisible: c })}><Icon name="cog" /><span className="tw-ml-5">Review Settings</span></a></li>*/}
                      {c.isEditable && <li><a data-target="#" role="button" onClick={() => this.showCollectionEditor(c)}><Icon name="pencil" /><span className="tw-ml-5">Edit Collection</span></a></li>}
                      <li role="separator" className="divider"></li>
                      <li>
                        <a
                          data-target="#"
                          role="button"
                          onClick={() => toggleDashboardCollection({
                            collection: c,
                            done: (data) => {
                              this.setState({
                                collections: collections.map((_c) => _c.id === c.id ? data.collection : _c)
                              });
                            }
                          })}
                        >
                          <Icon name="pushpin" /><span className="tw-ml-5">Unpin from Dashboard</span>
                        </a>
                      </li>
                    </>
                  }
                  right={true}
                />
                
              </div>
              <CollectionProgressBars
                collection={c}
              />
              <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-5 tw-mb-5">
                <button className="btn btn-block btn-success joystix" disabled={((c.proOnly || !c.type) && !user.isPro) || (!maxReviewsPerRound && c.numPlaying === c.numSentences)} onClick={() => this.setState({ aboutToPlayCollection: c })}>Play</button>
                <div className="tw-flex sm:tw-min-w-96">
                  <button className="btn btn-success-outline btn-sm joystix tw-flex-1" disabled={!c.numReadyForReview} onClick={() => this.setState({ aboutToPlayCollection: c, aboutToPlayScope: "ready_for_review" })}>Review ({c.numReadyForReview})</button>
                </div>
              </div>
              <div className="tw-flex tw-flex-row">
                <div className="tw-flex-1">
                  {(c.proOnly || !c.type) && !user.isPro && <span className="joystix tw-mr-5">Pro!</span>}
                  {!maxReviewsPerRound && c.numPlaying === c.numSentences && (
                    <div className="tw-mr-5">Max reviews per round set to 0 and no more new sentences! 🎉</div>
                  )}
                  <CollectionPanelPlayedToday
                    collection={c}
                  />
                </div>
                <div>
                  {!!c.numMarkedAsKnown && <>Marked as Known: {c.numMarkedAsKnown}</>}
                </div>
              </div>
            </Panel>
          ))}
          <div className="tw-flex tw-flex-row">
            <div className="tw-flex-1">
              <button className="btn btn-default btn-lg tw-mr-5" onClick={() => this.setState({ activeTab: "collections" })}><Icon name="plus" /> Add Collection</button>
            </div>
            <div>
              <button className="btn btn-default btn-xs tw-mr-5" id="reset-progress-btn" data-toggle="modal" data-target="#reset-progress-modal">
                Reset<span className="tw-hidden md:tw-inline"> Progress</span>
              </button>
              <button className="btn btn-default btn-xs" id="delete-language-pairing-btn" data-toggle="modal" data-target="#delete-language-pairing-modal">
                Delete<span className="tw-hidden md:tw-inline"> Language Pairing</span>
              </button>
            </div>
          </div>
          <div className="md:tw-hidden tw-my-5">
            <DarkModeToggle
              gameSettingsUrl={gameSettingsUrl}
            />
          </div>
        </div>
      </>
    );
  }

  render() {
    const {
      baseLanguageFlagIso,
      baseLanguageName,
      blogPostsPath,
      clozeReadingPath,
      languagePairings,
      monthlyCollectionPollUrl,
      targetLanguageFlagIso,
      targetLanguageName,
      unseenUserNotificationsCount,
      userProfilePath
    } = this.props;

    const {
      activeTab,
      gameSettingsUrl,
      loading,
      user
    } = this.state;

    console.log("languagePairings", languagePairings);

    if(loading) {
      return (
        <div className="tw-mt-10 tw-flex tw-flex-column tw-justify-center tw-flex-1">
          <Loading />
        </div>
      );
    }

    return (
      <div id="dashboard" className="container-fluid tw-flex tw-flex-1 tw-ml-0 2xl:tw-max-w-screen-2xl 2xl:tw-my-0 2xl:tw-mx-auto">
        <div className="tw-flex tw-flex-row tw-flex-1 -tw-mb-20">
          <div className="tw-hidden md:tw-block tw-w-80 tw-pt-5 tw-pb-20" style={{ backgroundColor: "#464646", borderRight: "3px solid #000", marginLeft: -15, paddingLeft: 15 }}>
            <div className="tw-flex tw-flex-row tw-items-center tw-mb-5">
              <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M12.7875 23.5429L14.7805 25.5341H21.3061V32.0373L23.2737 34H34.1826V2.05782L32.1197 0H21.2966V4.24881H17.05V6.22102L15.0792 4.25832H12.7843V1.95953L10.8199 0H0V32.0373L1.96755 34H12.7875V23.5429Z" fill="#0A0A0A"/>
                <path d="M22.4466 30.8928H30.9684V1.14795H22.4466V5.39676H18.1873V9.64557H13.928V5.39676H9.66866V1.14795H1.15002V30.8928H9.66866V18.1432H13.928V22.3952H18.1873V18.1432H22.4466V30.8928Z" fill="#5CB85B"/>
              </svg>
              <div className="joystix tw-ml-2 tw-text-white">Clozemaster</div>
            </div>
            <ul className="tw-list-none tw-p-0" style={{ marginLeft: -15 }}>
              {[["dashboard", "Dashboard"], ["collections", "Collections"], /*["stats", "Stats"], */ !!clozeReadingPath && ["cloze-reading", "Cloze-Reading", clozeReadingPath]/*,["blog-posts", "Blog Posts", blogPostsPath]*/].filter((li) => li).map((li) => (
                <li key={li[0]}><a data-target="#" href={li[2]} role="button" className={`hover:tw-bg-success hover:tw-text-black tw-block tw-p-6 joystix ${activeTab === li[0] ? "tw-bg-success tw-text-black" : "tw-text-white"}`} onClick={() => this.setState({ activeTab: li[0] })} style={{ textDecoration: "none" }}>{li[1]}</a></li>
              ))}
            </ul>
            <div className="tw-text-white" style={{ paddingRight: 15 }}>
              {!user.isPro && (
                <div className="tw-mt-20">
                  <div className="tw-text-center tw-mb-2">Get fluent faster.</div>
                  <a href="/pro" className="btn btn-attention btn-block joystix">Clozemaster<br />Pro</a>
                </div>
              )}
              <div className="tw-mt-20 tw-text-center">
                <DarkModeToggle
                  gameSettingsUrl={gameSettingsUrl}
                />
              </div>
              {!!monthlyCollectionPollUrl && (
                <div className="tw-mt-20 tw-text-center">
                  What collections would you like to see added?
                  <a href={monthlyCollectionPollUrl} className="btn btn-default btn-block tw-mt-2 joystix">Vote now!</a>
                </div>
              )}
            </div>
          </div>

          <div className="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 md:tw-hidden tw-bg-black tw-flex tw-flex-row" style={{ borderTop: "3px solid #000", zIndex: 1035 }}>
            {[
              ["dashboard", "Dashboard", "home"],
              ["collections", "Collections", "tasks"],
              ["", "Pro", "star"],
              /*["stats", "Stats", "stats"],*/
              !!clozeReadingPath && ["cloze-reading", "Cloze-Reading", "book", clozeReadingPath]
            ].filter((a) => a).map((a) => (
              <a
                data-target="#"
                role="button"
                className={`tw-decoration-none tw-block tw-flex-1 tw-py-6 ${activeTab === a[0] ? "tw-bg-success !tw-text-black" : "!tw-text-white "} text-center`}
                href={a[3]}
                key={a[0]}
                onClick={() => this.setState({ activeTab: a[0] })}
              >
                <Icon name={a[2]} />
                <span className="tw-block tw-text-sm">{a[1]}</span>
              </a>
            ))}
          </div>

          <div className="tw-flex-1 md:tw-ml-10 tw-pt-5 tw-pb-20">
            <div className="tw-flex tw-flex-row tw-mb-5">
              <div className="tw-flex tw-flex-1">
                <div className="btn-group">
                  <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown">
                    <FlagSprite flagIso={targetLanguageFlagIso} size={24} />
                    <span className="tw-mr-5"><FlagSprite flagIso={baseLanguageFlagIso} size={24} /></span>
                    <span className="joystix tw-hidden md:tw-inline tw-mr-5 tw-align-middle">{targetLanguageName} / {baseLanguageName}</span>
                    <span className="caret"></span>
                  </button>
                  <ul className="dropdown-menu">
                    {languagePairings.map((lp) => (
                      <li key={lp.id} style={{ backgroundColor: lp.currentStreakIncludesToday ? "rgba(92, 184, 92, 0.2)" : null }}>
                        <a href={`/l/${lp.slug}`}>
                          <FlagSprite flagIso={lp.targetLanguageFlagIso} size={24} />
                          <FlagSprite flagIso={lp.baseLanguageFlagIso} size={24} />
                          <span className="tw-ml-5 tw-align-middle"><span className="tw-font-bold">{lp.targetLanguageName}</span> / {lp.baseLanguageName}</span>
                          {!lp.showDaysPlayedInsteadOfStreak && <span className="tw-block joystix tw-text-base">Streak: <span className={lp.currentStreakIncludesToday ? "tw-color-streak-active" : ""}>{lp.currentStreakDays}</span> {lp.currentStreakIncludesToday && <Icon style={{ color: "#27bb4e" }} name="ok" />}</span>}
                          {!!lp.showDaysPlayedInsteadOfStreak && <span className="tw-block joystix tw-text-base">Days Played: <span className={lp.currentStreakIncludesToday ? "tw-color-streak-active" : ""}>{lp.daysPlayedCount}</span> {lp.currentStreakIncludesToday && <Icon style={{ color: "#27bb4e" }} name="ok" />}</span>}
                          <span className="tw-block joystix tw-text-base">Review: {lp.numReadyForReview.toLocaleString()}</span>
                          <span className="tw-block joystix tw-text-base">{lp.dailyGoalPointsPerDay ? <>Daily Goal: <span className={lp.numPointsToday >= lp.dailyGoalPointsPerDay ? "tw-color-success" : ""}>{lp.numPointsToday}</span> / {lp.dailyGoalPointsPerDay}</> : <>Points today: {lp.numPointsToday}</>}</span>
                        </a>
                      </li>
                    ))}
                    <li style={{ margin: "10px 20px" }}><a className="btn btn-default joystix btn-block" href="/languages"><Icon name="plus" /> Language Pairing</a></li>
                  </ul>
                </div>
                <button className="btn btn-default tw-ml-4" onClick={() => this.onSearchBtnClick()} title="Search sentences"><Icon name="search" /></button>
                <button className="btn btn-default tw-ml-4" onClick={() => this.showAddSentenceToCollectionModal()} title="Add sentence to collection"><Icon name="plus" /></button>
                <button className="btn btn-default tw-ml-4 more-stats" onClick={() => this.onMoreStatsBtnClick()} title="More stats"><Icon name="stats" /></button>
              </div>
              
              <div className="btn-group tw-flex">
                <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown">
                  <span className="caret"></span>
                </button>
                <ul className="dropdown-menu dropdown-menu-right">
                  <li>
                    <span style={{ display: "block", padding: "3px 20px" }}>
                      <small>Username:</small>
                      <br />
                      <span className="joystix tw-mr-2">{user.username}</span>
                      <small><a href="/settings">Edit</a></small>
                    </span>
                  </li>
                  <li role="separator" className="divider"></li>
                  <li><a className="joystix" href="/settings"><Icon name="wrench" /> Account</a></li>
                  <li><a className="joystix" href="/notifications"><Icon name="bell" /> Notifications{!!unseenUserNotificationsCount && <span className="badge tw-ml-5">{unseenUserNotificationsCount}</span>}</a></li>
                  <li><a className="joystix" href="/activity-feed"><Icon name="list-alt" /> Activity Feed</a></li>
                  <li><a className="joystix" href={userProfilePath}><Icon name="user" /> Profile</a></li>
                  <li><a className="joystix" href="/players/leaderboard"><Icon name="sort" /> Leaderboard</a></li>
                  <li><a className="joystix" href="https://forum.clozemaster.com"><Icon name="comment" /> Forum</a></li>
                  <li><a className="joystix" href="/logout"><Icon name="log-out" /> Logout</a></li>
                </ul>
              </div>
            </div>

            
            {this.renderActiveTab()}


            {/*
            <div className="pull-right">{this.renderProControls()}</div>
            <h1 className="title" style={{ marginTop: 0 }}>
              <span className="languages">{this.props.targetLanguageName} <small>from {this.props.baseLanguageName}</small></span>
              <span style={{ marginLeft: 10 }}>
                <FlagSprite flagIso={this.props.targetLanguageFlagIso} size={48} />
                <FlagSprite flagIso={this.props.baseLanguageFlagIso} size={48} />
              </span>
              <span className="joystix" style={{ fontSize: 16, display: 'block', marginTop: 4 }}>Dashboard</span>
            </h1>
            {this.renderMainContent()}
            */}
          </div>
        </div>
        {/*this.renderAnonUserPromo()*/}
        {this.renderModals()}
      </div>
    );
  }
}
